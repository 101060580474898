import {FormControl, FormLabel, Select, SelectProps} from "@chakra-ui/react";
import {ReactNode} from "react";
import { FormikInputProps } from "../Formik/types";

type FormikSelectProps = FormikInputProps & SelectProps & {
    children: ReactNode;
}

const DropSelect = ({flexBasis, placeholder, children, isRequired, name, label, ...props}: FormikSelectProps) => {
  return (
      <FormControl mt={"20px"} isRequired={isRequired} flexBasis={flexBasis}>
          {
              label.length !== 0 || isRequired ? (
                  <FormLabel>
                      {label}
                  </FormLabel>
              ) : <></>
          }
          <div>
              <Select focusBorderColor='primary' name={name}  {...props} flexBasis={flexBasis} placeholder={placeholder}
                      variant={"filled"}>
                  {
                      children
                  }
              </Select>
          </div>
      </FormControl>
  )
}

export default DropSelect