import {Box, Circle, Flex, Text} from "@chakra-ui/react";


type FormsHeadingProps = {
    title: string
    currentStep?: number,
    steps?: number
}


const BusinessFormsHeading = ({title, steps, currentStep}: FormsHeadingProps) => {
  return (
      <Box mb={"32px"}  borderBottom={"1px"} borderBottomColor={"#1fbc6c80"} pb={"10px"}>
          <Text fontSize={"24px"} color={"#1fbc6c"} >
              {title}
          </Text>
          <Flex align={"center"} gap={"8px"}>
              {
                  currentStep && (
                      <Text color={"#00000080"} fontSize={"18px"} fontWeight={"bold"}>
                          Step {currentStep} of {steps ? steps : 3}
                      </Text>
                  )
              }
              {
                  currentStep && (
                      <Circles count={steps ? steps : 3} currentStep={currentStep}/>
                  )
              }
          </Flex>
      </Box>
  )
}

const Circles = ({count, currentStep}: {count: number, currentStep: number})=> {
    return (
        <>
        {
            Array.from(Array(count).keys()).map((step, index)=> <Circle key={index} size={"16px"} background={currentStep >= index + 1 ? "#1fbc6c" : "#D9D9D9"}/>)
        }
        </>
    )
}

export default BusinessFormsHeading