import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './receipt';
import { apiRoutes } from 'Api/apiRoutes';

export const imageUploadApi = createApi({
    reducerPath: 'imageUploadApi',
    baseQuery,
    endpoints: (builder) => ({
        uploadImage: builder.mutation<string, FormData>({
            query(body) {
                return {
                    url: apiRoutes.logoUpload,
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { useUploadImageMutation } = imageUploadApi;