import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { documentTypeEnums } from 'Types/Enums/documentTypeEnums';
import { IQuotes } from 'Types/Interface/IQuotes'
import { IReceipt } from 'Types/Interface/IReceipt';
import {FormType} from "../../Types/Enums/documentTypeEnums";


 export type Document = IQuotes | IReceipt
interface IInitialDocumentState{
    documentData: Document | null;
    documentType: number;
    logo: string
    subTotal: number;
    tax: number;
    discount: number;
    total: number;
    formType?: FormType | null;
}


const initialState: IInitialDocumentState = {
    documentData: null,
    documentType: 0,
    logo: '',
    subTotal: 0,
    tax: 0,
    discount: 0,
    total: 0

}

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocumentType: (state, action: PayloadAction<number>) => {
            state.documentType = action.payload;
        },
        setDocumentData: (state, action: PayloadAction<Partial<Document>>) => {
            state.documentData = { ...state.documentData, ...action.payload } as Document;
        },
        setLogo: (state, action: PayloadAction<string>) => {
            state.logo = action.payload;
        },
        setTax: (state, action: PayloadAction<number>) => {
            state.tax = action.payload;
        },
        setDiscount: (state, action: PayloadAction<number>) => {
            state.discount = action.payload;
        },
        setSubTotal: (state, action: PayloadAction<number>) => {
            state.subTotal = action.payload;
        },
        setTotal: (state, action: PayloadAction<number>) => {
            state.total = action.payload;
        },
        resetDocumentState: () => {
            return initialState
        },
        setFormType: (state, action: PayloadAction<FormType>) => {
            console.log("form type set")
            state.formType = action.payload;
        }
    },
});

export const {
    setDocumentType, 
    setDocumentData, 
    setLogo,
    setDiscount,
    setSubTotal,
    setTax,
    setTotal,
    resetDocumentState,
    setFormType
} = documentSlice.actions;
export default documentSlice.reducer