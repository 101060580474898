import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "../../Services";

export const apiSlice = createApi(
    {
        reducerPath: "api",
        baseQuery,
        endpoints: (builder) => (
            {
                fillW2PdfPreview: builder.mutation({
                    query: (data) => ({
                        url: '/fillPdf',
                        method: 'POST',
                        body: data
                    })
                })
            }
        )
    }
)

export const {useFillW2PdfPreviewMutation} = apiSlice