import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiEnv } from '../Api/config';
import { IStripeResponse } from 'Types/Interface/IRequestBody';
import { Document } from 'Store/Slices';

export const baseQuery = fetchBaseQuery({
    baseUrl: getApiEnv(process.env.REACT_APP_ENVIRONMENT as 'develop' | 'production' | 'localhost' || 'develop'),
});

export const receiptApi = createApi({
    reducerPath: 'receiptApi',
    baseQuery,
    endpoints: (builder) => ({
        createReceipt: builder.mutation<IStripeResponse, { checkoutSessionId: string | null; checkoutAmount?: number; checkoutMode?: number, body: Document | null }>({
            query({ checkoutSessionId, checkoutAmount, checkoutMode, body }) {
                return {
                    url: `/Quotes/${checkoutSessionId}/${checkoutAmount}/${checkoutMode}`,
                    method: 'POST',
                    body
                }
            }
        })
    })
})

export const { useCreateReceiptMutation } = receiptApi;