import {useFormikContext} from "formik";
import {useEffect} from "react";
import {IDocumentBase} from "../Types/Interface/IQuotes";

const usePreferredPriceDiscount = <T extends IDocumentBase>() => {
    /*
        used for resetting the discountAmount if the percentage input is filled
        and for resetting the discountPercentage if the amount is filled
     */
    const {values, setFieldValue} = useFormikContext<T>()

    useEffect(() => {
        if (values.discountAmount > 0){
            setFieldValue("discountPercentage", 0)
        }
    }, [values.discountAmount]);

    useEffect(() => {
        if (values.discountPercentage > 0){
            setFieldValue("discountAmount", 0)
        }
    }, [values.discountPercentage]);
    return {values}
}

export default usePreferredPriceDiscount