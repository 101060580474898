import {Box, FormControl, FormLabel, Flex, Text, Input} from "@chakra-ui/react";
import {FormikInputProps} from "./types";
import {useState} from "react";

interface FormikFileInputProps extends FormikInputProps {
    accept?: string,
    setImage?: (image: string | ArrayBuffer | null | undefined) => void
    handleFileUpload?: (file: File) => void
}

const FormikFileInput = ({label, flexBasis, setImage, accept, handleFileUpload, ...props}: FormikFileInputProps) => {
    const [value, setValue] = useState("");


    return (
        <FormControl mt={"20px"} flexBasis={flexBasis}>
            <FormLabel>
                <Text fontSize={"24px"}>
                    {label}
                </Text>
                <Box mt={"8px"} border={"3px"} borderRadius={"8px"} borderStyle={"dashed"} cursor={"pointer"} borderColor={"primary"}>

                    <Flex align={"center"} justifyContent={"center"} py={"12px"}>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.1803 15.2343C15.8511 15.2343 17.2056 13.8798 17.2056 12.2089C17.2056 10.5381 15.8511 9.18359 14.1803 9.18359C12.5094 9.18359 11.1549 10.5381 11.1549 12.2089C11.1549 13.8798 12.5094 15.2343 14.1803 15.2343Z"
                                stroke="#1FBC6C" strokeWidth="2.26901" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M20.231 3.13281H14.1803C6.6169 3.13281 3.59155 6.15816 3.59155 13.7215V22.7976C3.59155 30.361 6.6169 33.3863 14.1803 33.3863H23.2563C30.8197 33.3863 33.8451 30.361 33.8451 22.7976V15.2342"
                                stroke="#1FBC6C" strokeWidth="2.26901" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M24.3907 7.6709H32.7105" stroke="#1FBC6C" strokeWidth="2.26901" strokeLinecap="round"/>
                            <path d="M28.5507 11.8305V3.51074" stroke="#1FBC6C" strokeWidth="2.26901"
                                  strokeLinecap="round"/>
                            <path
                                d="M4.60486 28.7727L12.0624 23.7658C13.2574 22.9641 14.9818 23.0548 16.0558 23.9776L16.555 24.4162C17.7349 25.4297 19.6409 25.4297 20.8207 24.4162L27.1135 19.016C28.2934 18.0025 30.1993 18.0025 31.3792 19.016L33.8449 21.1337"
                                stroke="#1FBC6C" strokeWidth="2.26901" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <Text ml={"8px"} color={"#1FBC6C"} fontSize={"24px"} textOverflow={"ellipsis"}>
                            {
                                value === "" ? "Add Image" : value.split("\\")[2]
                            }
                        </Text>
                    </Flex>
                </Box>
            </FormLabel>
            <Input
                display={"none"}
                {...props} accept={accept} onChange={async (event) => {
               if (event.target.files && event.target.files[0]) {
                   setValue(event.target.value)

                   if (handleFileUpload) {
                       handleFileUpload(event.target.files[0])
                   }
               }
            }}/>
        </FormControl>
    )
}

export default FormikFileInput