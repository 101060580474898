import {Box, Flex, Text, Image, Center} from "@chakra-ui/react";
import drake from "../../../Assets/Images/Drake.jpg"
import berret from "../../../Assets/Images/Berret.png"
import debra from "../../../Assets/Images/Debra.png"
import rating from "../../../Assets/Images/Rating.png"
import {theme} from "../../../Theme/Theme";


type ReviewProps = {
    image: string,
    name: string,
    review: string,
}

const reviews: ReviewProps[] = [
    {
        image: berret,
        name: "Berret Jake",
        review: " It's incredibly easy to use, and the documents it produces are professional and polished. I've saved so much time and effort since I started using it, and my clients have been impressed with the quality of the invoices and quotations I send them. Highly recommend!"
    },
    {
        image: debra,
        name: "Debra John",
        review: "I started using this document generator. As a freelancer, managing my paperwork used to be such a hassle, but now I can whip up a professional-looking paystub or invoice in minutes.Thank you for creating such a fantastic tool!"
    },
    {
        image: drake,
        name: "Drake Stanford",
        review: " It's incredibly easy to use, and the documents it produces are professional and polished. I've saved so much time and effort since I started using it, and my clients have been impressed with the quality of the invoices and quotations I send them. Highly recommend!"
    }
]


const CustomerFeedbackSection = () => {
    return (
        <Box  px={"48px"} w={"full"} overflow={"clip"} py={"98px"}>
            <Center>
                <Box w={630} mb={"89px"}>
                    <Text fontWeight={"bold"} textAlign={"center"} fontSize={"64px"}>
                        Customer Feedback
                    </Text>
                    <Center>
                        <Text fontSize={"24px"} w={549} textAlign={"center"} color={theme.secondaryFixed}>
                            Hear first hand accounts of customers of how our document generator has revolutionized everywhere
                        </Text>
                    </Center>
                </Box>
            </Center>

            <Flex w={"full"} sx={{
                "::-webkit-scrollbar": {
                    display: "none",
                },
            }} overflowX={"scroll"}  justifyContent={"space-between"}>
                {
                    reviews.map((review) => <Review key={review.name} image={review.image} name={review.name} review={review.review}/>)
                }
            </Flex>

        </Box>
    )
}

const Review = ({review, image, name }: ReviewProps) => {
    return (
        <Box  flexBasis={"30%"} minW={"300px"} borderRadius={"32px"} px={"16px"} py={"24px"} background={"#5FC09B"} mx={"24px"}>
            <Flex align={"center"} mb={"16px"}>
                <Image src={image} h={"80px"} w={"80px"} borderRadius={"full"} objectFit={"cover"}/>
                <Box ml={"24px"}>
                    <Text mb={"8px"}>
                        {name}
                    </Text>
                    <Image src={rating}/>
                </Box>
            </Flex>
            <Text fontSize={{base: "md", xl: "24px"}} textOverflow={"ellipsis"} color={"onPrimary"}>
                {review}
            </Text>

        </Box>
    )
}



export default CustomerFeedbackSection