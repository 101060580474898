import {Box,  Text} from "@chakra-ui/react";

type FormHeadingProps = {
    title: string
    description: string
    color?: string
}

const FormHeading = ({title, description, color = "primary"}: FormHeadingProps) => {
  return (
      <Box mb={"28px"}>
          <Text mb={"18px"} color={color} fontSize={"24px"} fontWeight={"semibold"}>
              {title}
          </Text>
          <Text fontSize={"16px"}>
              {description}
          </Text>
      </Box>
  )
}

export default FormHeading