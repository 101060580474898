import React from "react";
import {Box} from "@chakra-ui/react";

const FormPreviewLayout = ({children, flexBasis}: {children: React.ReactNode, flexBasis?: string | {[key: string]: string}}) => {
    return (
        <Box h={"max-content"} top={"30px"} pos={"sticky"} flexBasis={flexBasis || {base: "35%", md: "40%"}} pt={"48px"} >
        {
            children
        }
    </Box>
    )
}

export default FormPreviewLayout