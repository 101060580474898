import { documentTypeEnums } from "Types/Enums/documentTypeEnums";
import { IPricing } from "Types/Interface/IPricing";
import { AppRoutes } from "Routes/AppRoutes";

export const PricingConstants:IPricing[] = [
    {
        title: 'Paystub',
        price: '$12/Each',
        Features: [
            'Store information and create pay stubs with it quickly',
            'Add your company’s logo to personalize your pay stubs.',
            'We generate accurate calculations in accordance with current tax laws.',
            'You can make unlimited corrections at no additional cost.',
            'Sent to you immediately after purchase!',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: documentTypeEnums.Paystub,
        path: ''
    },
    {
        title: 'Invoices',
        price: '$9/Each',
        Features: [
            'Store information and create invoice with it quickly',
            'Flexible to add and remove items',
            `Add your company's logo to personalize your invoice`,
            'Gives you the best template',
            'You can make unlimited corrections at no additional cost',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: 0,
        path: ''
    },
    {
        title: 'Quotes',
        price: '$8.99/Each',
        Features: [
            'Flexible to add and remove items.',
            `Add your company's logo to personalize your invoice`,
            'We generate accurate calculations in accordance with the current tax laws',
            'Sent to you immediately after purchase',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: documentTypeEnums.Quotes,
        path: AppRoutes.quotes
    },
    {
        title: 'Reciepts',
        price: 'FREE',
        Features: [
            'Store information and create pay stubs with it quickly',
            `Add your company's logo to personalize your invoice`,
            'We generate accurate calculations in accordance with the current tax laws',
            'Sent to you immediately after purchase',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: documentTypeEnums.Receipt,
        path: AppRoutes.receipt
    },
    {
        title: 'W2',
        price: '$10/Each',
        Features: [
            'Store information and create W2 with it quickly',
            `Get the correct year and accepted W2 format.`,
            'We generate accurate calculations in accordance with the current tax laws',
            'You can make unlimited corrections at no additional cost',
            'Sent to you immediately after purchase',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: 0,
        path: ''
    },
    {
        title: '1099',
        price: '$12/Each',
        Features: [
            'Store information and create 1099 with it quickly',
            `Get the correct year and accepted W2 format.`,
            'Generate either 1099 NEC or 1099 MISC.',
            'You can make unlimited corrections at no additional cost',
            'Sent to you immediately after purchase',
            'Sent as a pdf which is easy to download and print.'
        ],
        documentTypeId: 0,
        path: ''
    }
]