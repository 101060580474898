import {FieldArray, FieldArrayRenderProps} from "formik";
import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import trash from "../../../Assets/Svg/trash.svg";
import {HasLineItems} from "../../../Types/Interface/IQuotes";
import {AddIcon} from "@chakra-ui/icons";
import BusinessFormsHeading from "./BusinessFormsHeading";
import {JSX} from "react/jsx-runtime";

interface BusinessLineItemsProps<T> {
    currentStep: number
    steps: number
    values: T
}

const BusinessLineItems: <T extends HasLineItems>({currentStep, steps, values}: BusinessLineItemsProps<T>) => JSX.Element = ({currentStep, steps, values}) => {

  return (
      <Box mb={"64px"}>
        <BusinessFormsHeading title={"Items"} steps={steps} currentStep={currentStep} />
        <FieldArray name={"lineItems"}>
          {
            (arrayHelpers) => (
                <>
                  {
                    values.lineItems.map((item, index) => <LineItem key={item.id} index={index} helper={arrayHelpers} len={values.lineItems.length}/>)
                  }
                  <Flex mt={"32px"} justifyContent={"center"}>
                    <Button
                        textAlign={"center"}
                        background={"#1FBC6C"}
                        onClick={() => arrayHelpers.push({id: Date.now(), description: "", lineTotal: 0, quantity: 0, rate: 0})}
                    >
                      <AddIcon h={'30px'} w={'30px'} p={1}  borderRadius={'50%'} border={'2px solid #fff'} color={'#fff'} boxSize={'25px'}/>
                      <Text ml={"12px"} color={'white'} >Add New Item</Text>
                    </Button>
                  </Flex>
                </>
            )
          }
        </FieldArray>
      </Box>
  )
}

const LineItem = ({index, helper, len}: {index: number, helper: FieldArrayRenderProps , len: number}) => {
  return (
      <Flex alignItems={"center"} gap={"20px"} justifyContent={"space-between"}>
        <FormikInput isRequired flexBasis={"40%"} label={`Item ${index + 1}`} name={`lineItems[${index}].description`} type={"text"}/>
        <FormikInput isRequired flexBasis={"20%"} label={"Quantity"} name={`lineItems[${index}].quantity`} type={"number"}/>
        <FormikInput isRequired flexBasis={"20%"} label={"Rate"} name={`lineItems[${index}].rate`} type={"number"}/>
        { len > 1 ? <Image cursor={'pointer'} boxSize={'40px'}  onClick={() => helper.remove(index)} src={trash} alignSelf={'flex-end'} /> : <Box w={"40px"}></Box>}
      </Flex>
  )
}

export default BusinessLineItems