import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './receipt';
import { IStripeResponse } from 'Types/Interface/IRequestBody';
import { Document } from 'Store/Slices';

export const quotesApi = createApi({
    reducerPath: 'quotesApi',
    baseQuery,
    endpoints: (builder) => ({
        createQuotes: builder.mutation<IStripeResponse, {checkoutSessionId: string | null; checkoutAmount?: number; checkoutMode?: number, body: Document | null}>({
            query({checkoutSessionId, checkoutAmount, checkoutMode, body}){
                return{
                    url: `/Quotes/${checkoutSessionId}/${checkoutAmount}/${checkoutMode}`,
                    method: 'POST',
                    body
                }
            } 
        })
    })
})

export const {useCreateQuotesMutation} = quotesApi