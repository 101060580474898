import React from "react";
import BusinessLineItems from "../../../Components/Forms/BusinessLineItems"
import {useFormikContext} from "formik";
import {IQuotes} from "../../../../Types/Interface/IQuotes";

const QuoteItems = () => {
    const {values} = useFormikContext<IQuotes>()
    return (
        <BusinessLineItems currentStep={3} steps={3} values={values}/>
    )
}


export default QuoteItems