import * as yup from "yup"

export const addressSchema = yup.object({
    stateAddress: yup.string().required("Input state address"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zipcode: yup.string().required("ZipCode is required"),
})

export const payerSchema = yup.object({
    name: yup.string().required("Name is required"),
    address: addressSchema,
    tin: yup.string().required("Enter a valid tin")
})

export const infoSchema = yup.object({
    year: yup.string().required("Year is required"),
    formCount: yup.number().required("Number of Forms is required"),
    email: yup.string().email('Please enter a valid email').required("Email is required"),
    agree: yup.boolean().required("Please agree before creating the form"),
    accept: yup.boolean().required("Please accept before creating the form"),
})

export const optionsSchema = yup.object({
    nonEmployeeCompensation: yup.number().nullable(),
    directSales: yup.boolean().default(false),
    incomeTax: yup.number().nullable(),
    income:  yup.number().nullable(),
    accountNumber: yup.string().nullable(),
    stateTax: yup.number().nullable(),
    payersNo: yup.string().nullable(),
    stateIncome: yup.number().nullable(),
})

export const recipientSchema = yup.object({
    name: yup.string().required("Name is required"),
    address: addressSchema,
    ssn: yup.string().required("Enter a valid ssn"),
    options: optionsSchema
})

export const f1099NecSchema = yup.object({
    info: infoSchema,
    payer: payerSchema,
    recipients: yup.array().of(recipientSchema)
})

export interface IPayer extends yup.InferType<typeof payerSchema>{}

export interface IRecipientNec extends yup.InferType<typeof recipientSchema>{}

export interface I1099NecOptions extends yup.InferType<typeof optionsSchema>{}

export interface I1099Info extends yup.InferType<typeof infoSchema>{}

export interface I1099Nec extends yup.InferType<typeof f1099NecSchema>{}