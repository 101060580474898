import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import {theme} from "../../../Theme/Theme";
import paystub from "../../../Assets/Images/Paystub.png"
import invoice from "../../../Assets/Images/Invoice.png"
import w2 from "../../../Assets/Images/W2.png"
import f1099 from "../../../Assets/Images/1099.png"
import React from "react";
import useDeviceQuery, {ScreenType} from "../../../Hooks/useDeviceQuery";


type TemplateProps = {
    asset: string
    label: string
}

const templates: TemplateProps[] = [
    {
        asset: paystub,
        label: "Paystub"
    },
    {
        asset: invoice,
        label: "Invoice"
    },
    {
        asset: w2,
        label: "W2"
    },
    {
        asset: f1099,
        label: "1099"
    }
]
const generateTemplateWidget = (template: TemplateProps) => <Template key={`${template.label}`} asset={template.asset} label={template.label}/>

const TemplateSection = () => {
    const device = useDeviceQuery()
    const isIpad = device === ScreenType.Ipad
  return (
      <Flex w={"100%"} overflow={"clip"} direction={"column"} align={"center"} background={!isIpad ? "white" : "#DEEFE8"} py={"83px"} px={"11px"}>
          {
              isIpad && <Text fontSize={"5xl"} fontWeight={"700"} mb={"24px"}>
                  Use Our Forms
              </Text>
          }
          {
              device === ScreenType.Macbook && <Flex w={"full"} px={"100px"} mb={"48px"} align={"center"} justifyContent={"space-between"}>
                  <Box>
                      <Text fontSize={"50px"} fontWeight={"semibold"} color={theme.onSurface}>
                          Features you can get
                      </Text>
                  </Box>
                  <Box w={461}>
                      <Text>
                          We offer a variety of interesting features that you can help increase your productivity and manage your time.
                      </Text>
                  </Box>


                  <Button variant={"solid"} background={theme.primary} color={theme.onPrimary} borderRadius={"full"} h={66} w={162}>
                      Get Started
                  </Button>
              </Flex>

          }
          <Flex justifyContent={isIpad ? "" : "space-between"} mx={"auto"} px={"48px"} w={"full"}>
              {
                 templates.map(generateTemplateWidget)
              }
          </Flex>
          <Button w={270} mt={"50px"} borderRadius={"10px"} variant={"outline"} borderWidth={"5px"} h={"50px"} borderColor={theme.primary} color={theme.primary}>
              View all templates
          </Button>

      </Flex>
  )
}

const Template: React.FC<TemplateProps> = ({asset, label}: TemplateProps) => {
    return (
        <Flex  maxW={"324px"} minW={"250px"} direction={"column"} align={"center"} background={"white"} mx={"21px"}>
            <Image src={asset} px={"43px"} py={"50px"} objectFit={"contain"}/>
            <Text pt={"30px"} pb={"50px"} fontSize={"25px"} fontWeight={"medium"}>
                {label.toUpperCase()}
            </Text>
        </Flex>
    )
}

export default TemplateSection