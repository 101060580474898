import React, { useEffect } from "react";
import { LandingLayout } from "Layouts/LandingLayout";
import { Button, Flex, Grid, Image, Img, List, ListItem, Spinner, Stack, Text } from "@chakra-ui/react";
import { PricingConstants } from "Constants/PricingConstants";
import Check from 'Assets/Svg/Check.svg';
import Badge from 'Assets/Svg/Badge.svg';
import { useGetPriceListsQuery } from "Services";
import { useToast } from "Hooks/useToast";
import { useNavigate } from "react-router-dom";

export const PricingJumbotron = () => {
    return(
        <Flex h={'178px'} w={'100%'} bg={'Green.100'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Text fontSize={['28px','40px']} textAlign={'center'} color={'white'} fontWeight={'700'}>Access the best features at an affordable price</Text>
            <Text fontSize={['18px','26px']} fontWeight={600}>Best prices you can find anywhere</Text>
        </Flex>
    )
}
 
export const Pricing  = () => {
    const {data, error, isError, isLoading} = useGetPriceListsQuery();
    const { alert } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if(isError){
            alert('error', error.toString())
        }
    }, [isError, error, alert])

    const mappedPricingConstants = PricingConstants.map((pricing) => {
        const matchedPrice = data?.prices.find(price => price.documentTypeId === pricing.documentTypeId);
        return { ...pricing, ...matchedPrice };
    });

    return(
        <>
            { isLoading ?
                <Flex 
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding='6' 
                    boxShadow='lg' 
                    bg='white'
                    h={'100vh'}
                    w={'full'}
                >
                    <Spinner size='xl' />
                </Flex> :

                <LandingLayout 
                    JumbotronSection={<PricingJumbotron />}           
                >
                    <Grid mt="60px" justifyItems="center" templateColumns={['repeat(1,2fr)','repeat(3, 2fr)']} gap={[8, 16]}>
                        {mappedPricingConstants.map((pricing, index) => (
                            <Stack key={index} position="relative">
                                <Img src={Badge} boxSize={['80px', '100px']} zIndex={999} pos="absolute" left={['-5%','-10%']} top={0} />
                                <Stack
                                    justify="center"
                                    align="center"
                                    border="1px solid white"
                                    borderRadius="30px"
                                    boxShadow="lg"
                                    w="388px"
                                    h="620px"
                                    p="30px"
                                >
                                    <Text fontSize="18px" fontWeight={600}>
                                        {pricing.title}
                                    </Text>
                                    <List spacing={4}>
                                    {pricing.Features.map((feature, index) => (
                                        <ListItem key={index} display="flex" gap={2} justifyContent="start" alignItems="start" fontSize="16px">
                                            <Image src={Check} boxSize="20px" />
                                            {feature}
                                        </ListItem>
                                    ))}
                                    </List>
                                
                                    <Text fontSize={['30px','35px']} color="#F2BD57" fontFamily="lemon">
                                        ${pricing.newPrice} / Each
                                    </Text>
                                
                                    <Button onClick={ () => navigate(pricing.path)} variant="primary" w="120px" h="40px" borderRadius="30px">
                                        Create
                                    </Button>
                                </Stack>
                            </Stack>
                        ))}
                    </Grid>
                </LandingLayout>
            }
        </>  
    )
}