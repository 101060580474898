import {Box, Flex, Image, Table, Text, Th, Tr, Tbody, Thead, Td, Tfoot} from "@chakra-ui/react";
import amazon from "../../Assets/Images/Amazon.png"
import React, {forwardRef, CSSProperties} from "react";
import '@fontsource-variable/inter'
import TemplateWrapper from "../Components/TemplateWrapper";


type StubTemplateProps = {}

const generalTemplateStyle: CSSProperties = {
    fontFamily: "Inter Variable, sans-serif",
    color: "#000000",
    fontSize: "12px"
}

const commonTableHeadingStyle: CSSProperties = {
    ...generalTemplateStyle,
    textTransform: "capitalize",
    fontWeight: "medium"
}


const StubTemplate = forwardRef<HTMLDivElement, StubTemplateProps>((props, ref) => {
    return (
        <TemplateWrapper>
          <Box style={generalTemplateStyle}>
              {/*    Heading Section*/}
              <Flex px={"16px"} justifyContent={"space-between"} align={"end"}>
                  <StubTemplateCompanyInfo/>
                  <StubTemplatePeriodInfo/>
              </Flex>
              <StubTemplateEmployeeInfo/>

              {/*    Body Section */}
              <StubTemplateTables/>

              {/* Advice info section*/}
              <StubTemplateAdviceInfo/>

              {/*    Footer Section*/}
              <StubTemplateBalanceFooter/>
          </Box>
        </TemplateWrapper>
    )
})


const StubTemplateTables = () => {
    return (
        <Box minH={"358px"} mt={"24px"}>
            <Table style={commonTableHeadingStyle}>
                <Thead>
                    <Tr>
                        <Th style={commonTableHeadingStyle}>Earnings</Th>
                        <Th style={commonTableHeadingStyle}>Rate</Th>
                        <Th style={commonTableHeadingStyle}>Hours</Th>
                        <Th style={commonTableHeadingStyle}>Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>Regular</Td>
                        <Td>70.0000</Td>
                        <Td>32.00</Td>
                        <Td>2,400.00</Td>
                    </Tr>
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td></Td>
                        <Td>Gross pay</Td>
                        <Td></Td>
                        <Td>2,400.00</Td>
                    </Tr>
                </Tfoot>
            </Table>
        </Box>
    )
}

const StubTemplateEmployeeInfo = () => {
    return (
        <Flex px={"16px"} mt={"50px"} align={"start"} justifyContent={"space-between"}>
            <Box flexBasis={"33%"} fontSize={"12px"} color={"#000000"}>
                <Flex justifyContent={"space-between"}>
                    <Text>Social Security Number:</Text>
                    <Text>123-45-6789</Text>
                </Flex>
                <Flex justifyContent={"space-between"}><Text>Taxable Marital Status:</Text> <Text>Single</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Exemptions / Allowances</Text> <Text>0</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Employee ID:</Text> <Text>123456789</Text></Flex>
            </Box>
            <Box flexBasis={"40%"} fontWeight={"medium"} color={"#1a1c21"}>
                <Box w={"139px"} pr={'8px'} fontSize={"12px"}>
                    <Text>Ogbon Sadiq</Text>
                    <Text>456 Oak Street, Houston, TX 77003.</Text>
                </Box>
            </Box>
        </Flex>
    )
}

const StubTemplatePeriodInfo = () => {
    return (
        <Box flexBasis={"40%"}>
            <Text fontSize={"24"} textAlign={"end"} fontWeight={"semibold"}>Earning Statement</Text>
            <Box fontSize={"12px"} my={"24px"} pr={"8px"} fontWeight={"medium"}>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Period Beginning</Text> <Text
                    color={"#5e6470"}>19/3/2024</Text></Flex>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Period Ending</Text> <Text
                    color={"#5e6470"}>21/3/2024</Text></Flex>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Pay Date</Text> <Text
                    color={"#5e6470"}>01/4/2024</Text></Flex>
            </Box>
        </Box>
    )
}

const StubTemplateCompanyInfo = () => {
    return (
        <Box flexBasis={"33%"}>
            {/*    Logo*/}
            <Flex direction={"column"} justifyContent={"end"} h={"100px"} w={"100px"} mb={"50px"}>
                <Image src={amazon} objectFit={"contain"}/>
            </Flex>
            {/*    Company Info*/}
            <Box w={"139px"} pr={'12px'} fontSize={"12px"} color={"#1a1c12"} fontWeight={"medium"}>
                {/*Company Name*/}
                <Text>Panda</Text>
                {/*    Company Address*/}
                <Text>12 Main street Houston, TX 77001</Text>
            </Box>
        </Box>
    )
}

const StubTemplateAdviceInfo = () => {
    return (
        <Flex px={"16px"} h={"179px"} justifyContent={"end"} align={"center"} mb={"24px"}>
            <Box flexBasis={"50%"} pr={"36px"} fontSize={"12px"} fontWeight={"medium"} color={"#1a1c21"}>
                <Flex justifyContent={"space-between"}><Text>Advice Number:</Text> <Text
                    color={"#5e6470"}>00345563</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Pay Date</Text> <Text
                    color={"#5e6470"}>21/3/2024</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Social security number</Text> <Text
                    color={"#5e6470"}>123-45-6789</Text></Flex>
            </Box>
        </Flex>
    )
}

const StubTemplateBalanceFooter = () => {
    return (
        <Box fontSize={"12px"} pt={"46px"} pb={"89px"} px={"66px"} backgroundColor={"#f9fafc"}>
            <Text fontWeight={"medium"} mb={"8px"}>
                Earnings
            </Text>
            <Flex borderTopWidth={"0.69px"} borderColor={"#000000"}>
                <Box py={"8px"} pl={"4px"} pr={"16px"}>
                    <Text>
                        This amount
                    </Text>
                </Box>
                <Flex fontWeight={"semibold"} flexGrow={"1"} borderBottomWidth={"0.69px"} borderColor={"#000000"}>
                    <Box py={"8px"} flexBasis={"80%"}>
                        <Text pl={"16px"}>
                            ONE HUNDRED AND TWENTY DOLLARS
                        </Text>
                    </Box>
                    <Box pl={"36px"} py={"8px"} flexBasis={"20%"} borderLeftWidth={"0.69px"} borderColor={"#000000"}>
                        <Text>
                            $120
                        </Text>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    )
}

export default StubTemplate
export {StubTemplate}