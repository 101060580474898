import {FormikInputProps} from "./types";
import {memo} from "react";
import { useField } from 'formik';
import TextInput from "../Inputs/TextInput";
import {Box} from "@chakra-ui/react";

const FormikInput = memo(
    function(props: FormikInputProps) {
        const [field, meta] = useField(props.name);
        
        return (
                <Box flexGrow={"1"}>
                    <TextInput {...field} focusBorderColor='primary' {...props} />
                    {meta.touched &&
                    meta.error && <div className="error">{meta.error}</div>}
                </Box>
        )
    }
)

export default FormikInput