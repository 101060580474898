import {
    Box,
    Button,
    Center,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { useAppSelector } from "../../Hooks/useAppSelector";
import { documentTypeEnums, FormType } from "../../Types/Enums/documentTypeEnums";
import { QuotesTemplate } from "../Templates/QuotesTemplate";
import { ReceiptTemplate } from "../Templates/ReceiptTemplate";
import React, { useState } from "react";
import PdfViewer from "./PdfViewer";
import { selectW2 } from "../../Store/Slices/w2Slice";
import useFillW2 from "../../Hooks/useFillW2";
import { Document } from "Store/Slices";
import useFill1099Misc from "../../Hooks/useFill1099Misc";
import { select1099State } from "../../Store/Slices/1099MiscSlice";
import useFill1099Nec from "../../Hooks/useFill1099Nec";
import { select1099NecState } from "../../Store/Slices/1099NecSlice";

const FormPreviewBar = ({ background, disabled, setDocumentType, values, formType }: { background: string, disabled: boolean, setDocumentType: () => void, values?: Document, formType?: FormType }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const w2 = useAppSelector(selectW2)
    const f1099MiscData = useAppSelector(select1099State)
    const f1099NecData = useAppSelector(select1099NecState)
    const logo = useAppSelector((state) => state.document.logo)
    const { data: w2Pdf, refreshData } = useFillW2(w2)
    const { data: f1099Misc, refreshData: refresh1099Misc } = useFill1099Misc(f1099MiscData)
    const { data: f1099Nec, refreshData: refres1099Nec } = useFill1099Nec(f1099NecData)
    const documentType = useAppSelector((state) => state.document.documentType);
    const [preview, setPreview] = useState(false)

    const renderTemplate = () => {
        switch (documentType) {
            case documentTypeEnums.Quotes:
                return <QuotesTemplate values={values!} logoUrl={logo} />;
            case documentTypeEnums.Receipt:
                return <ReceiptTemplate values={values!} logoUrl={logo} />;
            case documentTypeEnums.W2:
                return <PdfViewer src={w2Pdf} isPreview={true} onRefresh={() => { refreshData() }}></PdfViewer>
            case documentTypeEnums.F1099:
                if (formType === FormType.F1099Nec) {
                    return <PdfViewer src={f1099Nec} isPreview={true} onRefresh={() => { refres1099Nec() }}></PdfViewer>
                } else {
                    return <PdfViewer src={f1099Misc} isPreview={true} onRefresh={() => { refresh1099Misc() }}></PdfViewer>
                }
            default:
                return null;
        }
    };
    return (
        <>
            <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{formType}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {preview && renderTemplate()}
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={3} onClick={() => {
                            setPreview(false)
                            onClose()
                        }}>
                            Close
                        </Button>
                        <Button type={"submit"} background={background}>
                            Checkout
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Center>
                <Flex direction={"column"} align={"center"}>
                    <Flex gap={"24px"} mt={"24px"}>
                        <Button type={"submit"} background={background}>
                            Checkout
                        </Button>
                        <Button variant={"outline"} onClick={() => {
                            onOpen()
                            setDocumentType()
                            setPreview(true)
                        }}>
                            Preview
                        </Button>
                    </Flex>
                </Flex>
            </Center>
            <Box h={"20"}>
            </Box>
        </>
    )
}

export default FormPreviewBar