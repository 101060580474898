import {Box} from "@chakra-ui/react";

type PlusIconProps = {
    w?: string | number
    h?: string | number
    noSize?: boolean
}

const PlusIcon = ({w, h, noSize}: PlusIconProps) => {
    if (noSize){
        return (
            <svg style={{color: "#208c55"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
        )
    } else {
        return (
            <Box w={w ?? "24px"} h={h ?? "24px"}>
                <svg style={{color: "#208c55"}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </Box>
        )
    }
}

export default PlusIcon