import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './receipt';
import { apiRoutes } from 'Api/apiRoutes';
import { ICoinbaseResponse, ICoinbaseResquest,} from 'Types/Interface/ICheckout';

export const coinbaseApi = createApi({
    reducerPath: 'coinbaseApi',
    baseQuery,
    endpoints: (builder) => ({
        createCharge: builder.mutation<{"chargeResponse":ICoinbaseResponse}, {"chargeRequest":ICoinbaseResquest}>({
            query(body) {
                return {
                    url: apiRoutes.coinbase,
                    method: 'POST',
                    body,
                };
            },
        })
    }),
});

export const { useCreateChargeMutation } = coinbaseApi;