import { Flex, Grid, GridItem, Img, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import call from 'Assets/Svg/call.svg';
import Whatsapp from 'Assets/Svg/Whatsapp.svg';
import Linkedin from 'Assets/Svg/LinkedIn.svg';
import Email from 'Assets/Svg/email.svg';
import Instagram from 'Assets/Svg/instagram.svg';

export const LandingPageFooterLinks = [
	{
		title: 'Company',
		links: [{
			label: 'About us',
			path: ''
		}, {
			label: 'Pricing',
			path: ''
		}, {
			label: 'FAQS',
			path: ''
		}, {
			label: 'How it works ',
			path: ''
		},
		{
			label: 'Customer Stories ',
			path: ''
		}
		],
	},
	{
		title: 'Explore',
		links: [
			{
				label: 'Paystub',
				path: ''
			},
			{
				label:  'Invoice',
				path: ''
			},
			{
				label:  'Quotes',
				path: ''
			},
			{
				label: 'W2',
				path: ''
			},
			{
				label: '1099',
				path: ''
			},
			{
				label: 'Receipt',
				path: ''
			}
		],
	},
];

export const Footer = () => {
	return(
		<Grid 
			templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
			justifyContent='space-between'
			p={'55px'}
			borderTop={'1px solid'}
			borderColor={'primary'}
			gap={'100px'}
		>
			<GridItem>
				<Text fontSize={{md:'12px', lg:'18px'}} mb={'30px'} fontWeight={700}>About us</Text>
				<Text>
                    Lorem ipsum dolor sit amet consectetur. Ultrices sed fames praesent euismod arcu sollicitudin ac. 
                    Id aenean nunc tellus semper et viverra. Adipiscing bibendum quis urna urna. 
                    Pellentesque pretium tortor fusce interdum sem nibh proin. Est semper feugiat gravida nibh ut felis lacus. Pellentesque sed.
				</Text>
			</GridItem>
			{LandingPageFooterLinks.map((link) => (
				<VStack
					spacing={5}
					key={link.title}
					alignItems='flex-start'
					mr={[10, 8, 0]}
					mb={[10, 10, 0]}
				>
					<Text fontSize={{md:'12px', lg:'18px'}} fontWeight={700} mb={'20px'} >{link.title}</Text>

					{link.links.map((link) => (
						<Link fontSize={{md:'10px', lg:'14px'}}  key={link.label}>{link.label}</Link>
					))}
				</VStack>
			))}
			<GridItem>
				<Text fontSize={{md:'12px', lg:'18px'}} fontWeight={700} mb={'30px'}>Contact us</Text>
				<Flex justifyContent={'space-around'} alignItems={'center'}>
					<Img boxSize={'32.5px'} src={Whatsapp} />
					<Img boxSize={'32.5px'} src={call} />
					<Img boxSize={'32.5px'} src={Email} />
					<Img boxSize={'32.5px'} src={Linkedin} />
					<Img boxSize={'32.5px'} src={Instagram} />
				</Flex>
			</GridItem>
		</Grid>
	);
};