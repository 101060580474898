import {
    Box, Text, Flex, Select, Input, NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper, Button,
    NumberDecrementStepper, FormControl, FormLabel
} from "@chakra-ui/react";
import PlusIcon from "../../../../Components/PlusIcon";

const PayStubInfoForm = () => {
    return (
        <Box mb={"49px"}>
            <PayStubInfoHeading/>
            <Flex gap={"16px"} my={"36px"}>
                <PayStubPaymentInfoSelector/>
                <PayStubPayScheduleSelector/>
                <PayStubHourlyRateInput/>
            </Flex>
            <PayStubsCountInput/>
            <PayStubAdditionalEarnings/>
            <PayStubCustomerDedication/>
        </Box>
    )
}

const PayStubInfoHeading = () => {
    return (
        <Box borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"}>Paystub information</Text>
        </Box>
    )
}

const PayStubPaymentInfoSelector = () => {
    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                How are you paid?
            </FormLabel>
            <Select variant={"filled"}>
                <option value={"hourly"}>Hourly</option>
                <option value={"salary"}>Salary</option>
            </Select>
        </FormControl>
    )
}

const PayStubPayScheduleSelector = () => {
    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                Pay Schedule
            </FormLabel>
            <Select variant={"filled"}>
                <option value={"weekly"}>Weekly</option>
                <option value={"bi-weekly"}>Bi Weekly</option>
            </Select>
        </FormControl>
    )
}

const PayStubHourlyRateInput = () => {
    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                Hourly Rate
            </FormLabel>
            <Input type={"text"} value={20}/>
        </FormControl>
    )
}

const PayStubsCountInput = () => {
    return (
        <Flex>
            <Text mr={"24px"} fontSize={'20px'} fontWeight={"semibold"} color={"#208c55"}>
                How many paystubs do you need
            </Text>
            <NumberInput>
                <NumberInputField/>
                <NumberInputStepper>
                    <NumberIncrementStepper/>
                    <NumberDecrementStepper/>
                </NumberInputStepper>
            </NumberInput>
        </Flex>
    )
}

const PayStubAdditionalEarnings = () => {
    return (
        <Flex align={"center"} justifyContent={"space-between"} mt={"60px"} mb={"36px"}>
            <Box>
                <Text color={"#208c55"} fontSize={"18px"}>
                    Additional earnings
                </Text>
                <Text color={"#0000007F"}>
                    Add Bonuses, Overtime, tips, expenses
                </Text>
            </Box>
            <Button color={"#208c55"}  fontWeight={"normal"} borderColor={"#208c55"} leftIcon={<PlusIcon/>} variant={"outline"}>
                Add Earnings
            </Button>
        </Flex>
    )
}

const PayStubCustomerDedication = () => {
  return (
      <Flex align={"center"} justifyContent={"space-between"}>
          <Box>
              <Text color={"#208c55"} fontSize={"18px"}>
                  Customer Deduction
              </Text>
              <Text color={"#0000007F"} fontSize={"18px"}>
                  Deduct insurance, 401k, other taxes etc.
              </Text>
          </Box>
          <Button color={"#208c55"}  borderColor={"#208c55"} fontWeight={"normal"} leftIcon={<PlusIcon/>} variant={"outline"}>
              Add Deductions
          </Button>
      </Flex>
  )
}


export default PayStubInfoForm