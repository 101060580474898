import BusinessFormsHeading from "../../Components/Forms/BusinessFormsHeading";
import {Box, Flex, FormControl, FormLabel, Input} from "@chakra-ui/react";
import {PatternFormat} from "react-number-format";
import TextInput from "../../Components/Inputs/TextInput";
import DropSelect from "../../Components/Inputs/DropSelect";
import React, {ChangeEvent} from "react";
import {I1099Info} from "../validator";
import useFormCounter from "../../../Hooks/useFormCounter";

const NecBasicInfo = ({handleChange, info}: {handleChange: (e: ChangeEvent)=> void, info: I1099Info}) => {
  const formCounts = useFormCounter()
  return (
      <Box mb={"32px"}>
        <BusinessFormsHeading title={"Basic Info"} steps={3} currentStep={1}/>
        <Flex gap={"24px"} direction={{base: "column", md: "row"}}>
          <FormControl isRequired mt={"20px"}>
            <FormLabel>
              Tax year
            </FormLabel>
            <PatternFormat
                focusBorderColor="primary"
                customInput={Input}
                value={info.year}
                name={'info.year'}
                format={"####"}
                onChange={handleChange}
                type={"text"}
                placeholder={"YYYY"}/>
          </FormControl>
          <TextInput isRequired name={"info.email"} onChange={handleChange} value={info.email} label={"Email"}/>
        </Flex>
        <Flex gap={"24px"} w={"70%"}>
          <DropSelect
              isRequired
              flexBasis={"60%"}
              value={info.formCount}
              onChange={handleChange}
              label={"How many forms do you need?"} name={'info.formCount'}>
            {
              formCounts.slice(0, 3).map((count) => <option key={count}
                                                            value={count + 1}>{count + 1}</option>)
            }
          </DropSelect>
        </Flex>
      </Box>
  )
}

export default NecBasicInfo