
const apiEnv = {
	develop: process.env.REACT_APP_API_DEV,
	production: process.env.REACT_APP_API_PROD,
	localhost: process.env.REACT_APP_API_LOCALHOST,
	
};

export type Env = "develop" | "production" | "localhost";

export const getApiEnv = (env: Env) => {
	return apiEnv[env];
};