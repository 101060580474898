import {Box, Button, Center, Checkbox} from "@chakra-ui/react"
import PreviewStubTemplate from "../../Templates/StubTemplate";
const PayStubPreviewSection = () => {
  return (
      <Box pos={"sticky"} pt={"32px"} h={"max-content"} flexBasis={"50%"} top={"0"}>
          <Box w={"full"} mb={"32px"}>
              <Center>
                  <Box w={"75%"}>
                      <PreviewStubTemplate/>
                  </Box>
              </Center>
          </Box>
          <Box borderBottom={"1px"} pb={"24px"}  borderBottomColor={"#00000080"}>
              <Checkbox mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
              <Checkbox> I accept our Term of Use and Privacy Policy</Checkbox>
          </Box>
          <Center>
              <Button variant={"solid"} h={"78px"} w={"350px"} color={"#ffffff"} borderRadius={"30px"} mt={"28px"} backgroundColor={"#208c55"}>
                  CHECKOUT
              </Button>
          </Center>
      </Box>
  )
}

export default PayStubPreviewSection