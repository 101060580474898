import {Box, Button, Flex, Image, Spacer, Text, Center, Menu, MenuButton, MenuList, MenuItem,} from "@chakra-ui/react"
import logo from "../Assets/Images/logo.png"
import {NavLink, useNavigate} from "react-router-dom"
import {theme} from "../Theme/Theme";
import {AppRoutes} from "../Routes/AppRoutes";
import useDeviceQuery, {ScreenType} from "../Hooks/useDeviceQuery";
import React from "react";
import {ChevronDownIcon} from "@chakra-ui/icons";

type Page = {
    label: string,
    basePath?: string,
    hidden: boolean,
    items?: Item[]
}

type Item = {
    label: string,
    hidden: boolean,
    path: string
}

type TopNavBarProps = {
    pages: Page[]
}

const pages: Page[] = [
    {
        label: "Home",
        hidden: false,
        basePath: AppRoutes.home
    },
    {
        label: "Pay Stub",
        hidden: true,
        basePath: AppRoutes.payStub
    },
    {
        label: "W2",
        hidden: false,
        basePath: AppRoutes.wforms,
        items: [
            {
                label: "w-2",
                hidden: false,
                path: AppRoutes.w2
            },
            {
                label: "w-4",
                hidden: true,
                path: AppRoutes.w4
            },
            {
                label: "w-9",
                hidden: true,
                path: AppRoutes.w9
            }
        ]
    },
    {
        label: "1099",
        hidden: false,
        basePath: AppRoutes.f1099,
        items: [
            {
                label: "1099 Nec",
                hidden: false,
                path: AppRoutes.f1099Nec
            },
            {
                label: "1099 Misc",
                hidden: false,
                path: AppRoutes.f1099Misc
            },
            {
                label: "1099 K",
                hidden: true,
                path: AppRoutes.f1099K
            }
        ]
    },
    {
        label: "Real Estate",
        hidden: true,
        basePath: AppRoutes.realEstate,
        items: [
            {
                label: "Lease Agreement",
                hidden: true,
                path: AppRoutes.leaseAgreement
            },
            {
                label: "Sublease Agreement",
                hidden: true,
                path: AppRoutes.subleaseAgreement,
            },
            {
                label: "Eviction Notice",
                hidden: true,
                path: AppRoutes.evictionNotice
            }
        ]
    },
    {
        label: "Business",
        hidden: false,
        basePath: AppRoutes.receipt,
        items: [
            {
                label: "Quote",
                hidden: false,
                path: AppRoutes.quotes
            },
            {
                label: "Invoice",
                hidden: true,
                path: AppRoutes.invoice
            },
            {
                label: "Receipt",
                hidden: false,
                path: AppRoutes.receipt
            },
            {
                label: "Vehicle Bill of Sales",
                hidden: true,
                path: AppRoutes.vehicleBos
            },
            {
                label: "Void Check",
                hidden: true,
                path: AppRoutes.voidCheck
            },
            {
                label: "Power of Attorney",
                hidden: true,
                path: AppRoutes.powerAttorney
            },
            {
                label: "Employment Letter",
                hidden: true,
                path: AppRoutes.employmentLetter
            },
            {
                label: "Termination Letter",
                hidden: true,
                path: AppRoutes.terminationLetter
            },
            {
                label: "Truck Driver's Agreement",
                hidden: true,
                path: AppRoutes.truckDriverAgreement
            },
        ]
    },
    {
        label: "Pdf Editor",
        hidden: true,
        basePath: AppRoutes.pdfEditor
    },

]

const pagesIpad: Page[] = [
    {
        label: "Home",
        hidden: false,
        basePath: AppRoutes.home
    },
    {
        label: "Pay Stub",
        hidden: true,
        basePath: AppRoutes.payStub
    },
    {
        label: "Tax Forms",
        hidden: false,
        items: [
            {
                label: "w-2",
                hidden: false,
                path: AppRoutes.w2
            },
            {
                label: "w-4",
                hidden: true,
                path: AppRoutes.w4
            },
            {
                label: "w-9",
                hidden: true,
                path: AppRoutes.w9
            },
            {
                label: "1099 Nec",
                hidden: false,
                path: AppRoutes.f1099Nec
            },
            {
                label: "1099 Misc",
                hidden: false,
                path: AppRoutes.f1099Misc
            },
            {
                label: "1099 K",
                hidden: true,
                path: AppRoutes.f1099K
            },
            {
                label: "Lease Agreement",
                hidden: true,
                path: AppRoutes.leaseAgreement
            },
            {
                label: "Sublease Agreement",
                hidden: true,
                path: AppRoutes.subleaseAgreement,
            },
            {
                label: "Eviction Notice",
                hidden: true,
                path: AppRoutes.evictionNotice
            }

        ]
    },
    {
        label: "Business Forms",
        hidden: false,
        items: [
            {
                label: "Quote",
                hidden: false,
                path: AppRoutes.quotes
            },
            {
                label: "Invoice",
                hidden: true,
                path: AppRoutes.invoice
            },
            {
                label: "Receipt",
                hidden: false,
                path: AppRoutes.receipt
            },
            {
                label: "Vehicle Bill of Sales",
                hidden: true,
                path: AppRoutes.vehicleBos
            },
            {
                label: "Void Check",
                hidden: true,
                path: AppRoutes.voidCheck
            },
            {
                label: "Power of Attorney",
                hidden: true,
                path: AppRoutes.powerAttorney
            },
            {
                label: "Employment Letter",
                hidden: true,
                path: AppRoutes.employmentLetter
            },
            {
                label: "Termination Letter",
                hidden: true,
                path: AppRoutes.terminationLetter
            },
            {
                label: "Truck Driver's Agreement",
                hidden: true,
                path: AppRoutes.truckDriverAgreement
            },
        ]
    },
]

const TopNavBar = () => {
    const device = useDeviceQuery()
    if (device === ScreenType.Iphone) {
        return <TopNavIphone/>
    } else if (device === ScreenType.Ipad) {
        return <TopNavIpad/>
    } else if (device === ScreenType.Macbook) {
        return <TopNavMacbook/>
    } else {
        return <></>
    }
}

const TopNavMacbook = () => {
    return (
        <Flex h={'20'} align={"center"} background={"white"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
            <Flex direction={"column"} align={"center"} h={"full"} ml={"24px"} py={'12px'}>
                <Image h={"calc(100% - 16px)"} src={logo} objectFit={"contain"}/>
                <Text color={"primary"}>PhcWorkHub</Text>
            </Flex>
            <Spacer/>
            <TopNavLinks pages={pages}/>
            <Spacer/>
            <ButtonRow/>
        </Flex>
    )
}

const TopNavDropDown = ({page} : {page: Page}) => {
    return (
        <Menu >
            <MenuButton w={"max-content"} as={Flex} align={"center"} h={"full"} color={theme.primary} mr={"5"} fontSize={{xl: "16px", base: "14px"}} fontWeight={"extrabold"} px={'2.5'}
                        borderBottom={ "2px"} borderBottomColor={"transparent"}>
                {page.label.toUpperCase()}
                <ChevronDownIcon />
            </MenuButton>
            <MenuList minW={"max-content"}>
                {
                    page.items?.map((item, index)=> <MenuItem hidden={item.hidden} key={index} m={"0"} w={"max-content"}><TopNavLink hidden={item.hidden} label={item.label} basePath={item.path}/></MenuItem>)
                }
            </MenuList>
        </Menu>
    )
}

const TopNavIpad = () => {
    return (
        <Flex h={'20'} align={"center"} justifyContent={"space-between"} background={"white"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
            <Flex direction={"column"} align={"center"} h={"full"} ml={"24px"} py={'12px'}>
                <Image src={logo} h={"calc(100% - 16px)"} objectFit={"contain"}/>
                <Text flexGrow={'1'} color={"primary"}>PhcWorkHub</Text>
            </Flex>
            <Flex fontSize={{xl: "20px", base: "14px"}}>
                <TopNavLinks pages={pagesIpad}/>
            </Flex>
            <ButtonRow isIpad/>
        </Flex>
    )
}
const TopNavIphone = () => {
    return (
        <Flex h={'16'} align={"center"} justifyContent={"space-between"} w={"full"} zIndex={"docked"} pos={"fixed"} top={"0"}>
            <Box h={"24px"} w={"24px"} color={"primary"} ml={"24px"}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"/>
                </svg>
            </Box>
            <Text fontWeight={"700"} fontSize={"md"}>
                PHC WORK PRO
            </Text>
            <Center h={"full"} mr={"16px"} py={"12px"}>
                <Image src={logo} h={"full"} objectFit={"contain"}/>
            </Center>
        </Flex>
    )
}

const ButtonRow = ({isIpad}: { isIpad?: boolean }) => {
    const navigate = useNavigate();
    return (
        <Flex direction={"row"} mr={"10"} align={"center"}>
            {
                isIpad && (
                    <Box visibility={"hidden"} mr={"16px"} color={"primary"} h={"24px"} w={"24px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"/>
                        </svg>
                    </Box>
                )
            }
            <Button visibility={"hidden"} variant={"solid"} size={{base: "sm", xl: "lg"}} onClick={() => {
                navigate(AppRoutes.login)
            }}>
                Login
            </Button>
            <Button visibility={"hidden"} variant={"outline"} size={{base: "sm", xl: "lg"}} ml={"16px"} onClick={() => {
                navigate(AppRoutes.signUp)
            }}>
                Sign Up
            </Button>
        </Flex>
    )
}

const TopNavLinks = ({pages}: Readonly<TopNavBarProps>) => {
    const createNavLink = (page: Page, index: number) => {
        if (page.items !== undefined && !page.hidden){
            return <TopNavDropDown page={page} key={page.label + " " + index}/>
        } else if (page.hidden) {
            return (<Box hidden key={page.label + " " + index}></Box>)
        } else {
            return  <TopNavLink hidden={page.hidden} key={page.label + " " + index} label={page.label}
                                basePath={page.basePath}/>
        }
    }
    return (
        <Flex>
            {
                pages.map(createNavLink)
            }
        </Flex>
    )
}

const TopNavLink = (page: Page) => {
    if (page.hidden){
        return (<></>)
    }
    return (
        <NavLink to={`${page.basePath}`}>
            {
                ({isActive}) =>
                    <Flex align={"center"} h={"full"} color={theme.primary} mr={"5"} fontSize={{xl: "16px", base: "14px"}} fontWeight={"extrabold"} px={'2.5'}
                         borderBottom={ "2px"} borderBottomColor={isActive ? theme.primary : "transparent"}>
                        {page.label.toUpperCase()}
                    </Flex>

            }
        </NavLink>
    )
}


export default TopNavBar