import {FormControl, FormLabel, Textarea, TextareaProps} from "@chakra-ui/react";
import { FormikInputProps } from "../Formik/types";


const NoteInput = ({label, flexBasis, name, ...props} : FormikInputProps & TextareaProps) => {
   return (
       <FormControl mt={"20px"} flexBasis={flexBasis}>
           <FormLabel>
               {label}
           </FormLabel>
           <div>
               <Textarea name={name} focusBorderColor='primary'  {...props}/>
           </div>
       </FormControl>
   )
}


export default NoteInput
