import { Flex, Stack, Input, Button, Text, Box,Image } from "@chakra-ui/react";
import { SplitLayout } from "Layouts/SplitLayout";
import Onboarding from 'Assets/Svg/Onboarding.svg';
import React from "react";

export const SignUpPage = () => {
    return(
        <SplitLayout buttonTitle="Sign Up">
           
                <Flex  direction={'column'} w={'100%'} minHeight={'100%'} overflow={'hidden'} > 
                    
                    <Stack minH={'100%'} justify={'center'} align={'center'} >
                        <Box mb={'30px'}>
                            <Text mb={'16px'} fontFamily={'Zen Kaku Gothic Antique'} color={'black'} fontSize={'26px'} fontWeight={'700'}>Get signed in Here. Today!!!!</Text>
                            <Text color={'#616161'} textAlign={'center'} fontSize={'14px'}>No CREDIT CARD REQUIRED!</Text>
                        </Box>

                        <Box w={'500px'}>
                            <Flex mb={'10px'}>
                                <Input border={'1px solid LightGrey'} borderRadius={'2px'} mr={'6px'} placeholder="FirstName"/>
                                <Input border={'1px solid LightGrey'} borderRadius={'2px'} placeholder="LastName"/>
                            </Flex>
                            <Input border={'1px solid LightGrey'} mb={'10px'} borderRadius={'2px'} placeholder="Email" />
                            <Input border={'1px solid LightGrey'} borderRadius={'2px'} placeholder="Password" />
                        </Box>
                       <Button w={'500px'} h={'50px'} variant={'primary'}>Sign Up</Button>
                        <Text fontSize={'11px'} color={'LightGrey'}>By Creating an Account, it means you agree to our Privacy policy and Terms of service</Text>
                    </Stack>
             
            </Flex>
            <Flex justifyContent={'center'} alignItems={'center'} w={'100%'}>
                <Image src={Onboarding} boxSize={'350px'} />
            </Flex>
            
        </SplitLayout>
    )
}