import { Box } from "@chakra-ui/react";
import React from "react";
import { Navbar } from "Components/Navbar";
import { Footer } from "Components/Footer";

interface ILandingLayoutProps{
    JumbotronSection: React.ReactNode;
    children: React.ReactNode
}

export const LandingLayout = ({JumbotronSection, children}:ILandingLayoutProps) => {
    return(
        <Box overflowX="hidden">
            <Box>
                <Navbar/>
                {JumbotronSection}
            </Box>
            {React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.shouldIgnorePadding) {
						return (
							<Box  maxW={'1440px'} m={'0 auto'} mb={['50px', '100px']}>
								{child}
							</Box>
						);
					}

					return (
						<Box maxW={'1440px'} m={'0 auto'} mb={['50px', '100px']} px={['0', '6', '10']}>
							{child}
						</Box>
					);
				}
				return child;
			})}
        
            <Footer/>	
        </Box>
    )
}