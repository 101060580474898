import { Flex, Text, Button } from '@chakra-ui/react';
import React from 'react';

type SplitScreenPropTypes = {
  children: [React.ReactNode, React.ReactNode];
  leftWeight?: number;
  rightWeight?: number;
  hideLeft?: boolean;
  buttonTitle?: string;
};

export const SplitLayout = ({
	children,
	leftWeight = 2,
	rightWeight = 1,
	hideLeft,
    buttonTitle
}: SplitScreenPropTypes) => {
	const [left, right] = children;
	return (
		<Flex  h="100vh" overflow="hidden" data-testid="splitscreen-parent" bg={'white'}>
			
			<Flex direction={'column'} flex={leftWeight} data-testid="left-child" >
                <Flex px={'10'} justifyContent={'space-between'} pt={'20px'}>
                    <Text>Logo</Text>
                    <Flex justifyContent={'center'} alignItems={'center'} >
                        <Text fontSize={'14px'} mr={'10px'}>Use as a Guest</Text>
                        <Button variant={'secondary'} borderColor={'#212121'} color={'#212121'}>{buttonTitle}</Button>
                    </Flex>
                </Flex>
				{left}
			</Flex>
		
			<Flex flex={rightWeight} bg={'Green.100'} data-testid="right-child">
				{right}
			</Flex>
		</Flex>
	);
};
