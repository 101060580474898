import FormPreviewLayout from "../../../Layouts/FormPreviewLayout";
import { Box, Checkbox, } from "@chakra-ui/react";
import { useState } from "react";
import { useAppSelector } from "../../../Hooks/useAppSelector";
import { selectW2, setAcceptTos, setAgree, } from "../../../Store/Slices/w2Slice";
import DropSelect from "Pages/Components/Inputs/DropSelect";
import PdfViewer from "../../Components/PdfViewer";
import useFillW2 from "Hooks/useFillW2";
import { useAppDispatch } from "../../../Hooks/useAppDispatch";
import FormPreviewBar from "../../Components/FormPreviewBar";
import { setDocumentType } from "../../../Store/Slices";
import { documentTypeEnums, FormType } from "../../../Types/Enums/documentTypeEnums";


const W2Preview = () => {
    const [employeeIndex, setEmployeeIndex] = useState(0);
    const data = useAppSelector(selectW2)
    const { data: w2Pdf, refreshData, loading } = useFillW2(data)

    const dispatch = useAppDispatch()

    return (
        <FormPreviewLayout>
            <Box >
                <PdfViewer src={w2Pdf} documentLoading={loading} onRefresh={() => { refreshData() }}></PdfViewer>
            </Box>
            <DropSelect label={"Choose Form"} name={'forms'} value={employeeIndex} onChange={(event) => setEmployeeIndex(parseInt(event.target.value))}>
                {
                    data.employees.map((employee, index) => <option key={index} value={index}>View Employee {index + 1} form</option>)
                }
            </DropSelect>
            <Box borderBottom={"1px"} pb={"24px"} mt={"24px"} borderBottomColor={"#00000080"}>
                <Checkbox name={"agree"} isChecked={data.agree} onChange={() => dispatch(setAgree(!data.agree))} mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
                <Checkbox isChecked={data.accept} onChange={() => dispatch(setAcceptTos(!data.accept))} name={"accept"}> I accept our Term of Use and Privacy Policy</Checkbox>
            </Box>
            <FormPreviewBar setDocumentType={() => dispatch(setDocumentType(documentTypeEnums.W2))} background={!(data.accept && data.agree) ? "grey" : "primary"} disabled={!(data.accept && data.agree)} formType={FormType.W2} />

        </FormPreviewLayout>
    )
}

export default W2Preview;