import {Box, Checkbox, Flex, FormControl, FormLabel, Input, Select, Text} from "@chakra-ui/react";

const EmployeeInfoForm = () => {
  return (
      <Box mb={"36px"}>
        <EmployeeInfoFormHeading/>
          <EmployeeNameInput/>
          <Flex gap={"24px"} my={"20px"}>
              <EmployeeRoleInput/>
              <EmployeeAddressInput/>
          </Flex>
          <Flex align={"end"} gap={"24px"} mb={"50px"}>
              <EmployeeCityInput/>
              <EmployeeStateSelector/>
              <EmployeeZipCodeInput/>
          </Flex>
          <Checkbox>Check this box to adjust hire date if hired recently</Checkbox>
      </Box>
  )
}


const EmployeeInfoFormHeading = () => {
  return (
      <Flex justifyContent={"space-between"} borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
        <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"}>
          Employee's Information
        </Text>
        <Checkbox fontSize={"18px"}>Contractor</Checkbox>
      </Flex>
  )
}

const EmployeeNameInput = () => {
    return (
        <FormControl mt={"20px"}>
            <FormLabel>
                Employee Name
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const EmployeeRoleInput = () => {
    return (
        <FormControl>
            <FormLabel>
                Role (Optional)
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const EmployeeAddressInput = () => {
    return (
        <FormControl>
            <FormLabel>
                Employee Address
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const EmployeeCityInput = () => {
    return (
        <FormControl flexBasis={"50%"}>
            <FormLabel>
                City
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const EmployeeZipCodeInput = () => {
    return (
        <FormControl flexBasis={"25%"}>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const EmployeeStateSelector = () => {
    return (
        <Select flexBasis={"25%"} placeholder={"State"} variant={"filled"}>
            <option>Ohio</option>
            <option>Houston</option>
        </Select>
    )
}


export default EmployeeInfoForm