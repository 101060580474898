import React, { useEffect, useState } from "react";
import {
  VStack, Text, HStack, Image, FormControl, FormLabel, Input,
  InputGroup, InputLeftElement, NumberInput, NumberInputStepper,
  NumberDecrementStepper, NumberIncrementStepper, NumberInputField,
  Checkbox, Button, chakra, useDisclosure, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalOverlay, Box, Spinner, Flex
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";

import shoppingCart from "../../Assets/Svg/ShoppingCart.svg";
import { Navbar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Items } from "./Items";
import { useAppSelector } from "Hooks/useAppSelector";
import { useGetPriceByDocumentTypeQuery, useCreateCheckoutSessionMutation, useCreateChargeMutation } from "Services";
import { useToast } from "Hooks/useToast";
import { documentTypeEnums } from "Types/Enums/documentTypeEnums";
import { getStripeKey } from "../../Services/utils";

const stripePromise = loadStripe(`${getStripeKey()}`);
export const Checkout = () => {
  const documentType = useAppSelector(state => state.document.documentType);
  const { data, isLoading, error: documentPriceError, isSuccess: priceSuccess } = useGetPriceByDocumentTypeQuery(documentType);
  const [checkoutSession, { data: checkoutSessionData, error, isError }] = useCreateCheckoutSessionMutation();
  const [createCharge, { data: createChargeData, isSuccess: chargeDataSuccess }] = useCreateChargeMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { alert } = useToast();
  const [hostedUrl, setHostedUrl] = useState("");

  useEffect(() => {
    if (documentPriceError) {
      alert("error", "Failed to fetch price data.");
    }
  }, [documentPriceError, alert]);

  useEffect(() => {
    if (priceSuccess) {
      checkoutSession({
        "checkoutSessionDto": {
          "checkoutItemDtos": [{
            "documentType": documentType,
            "quantity": 1,
            "unitPrice": 100
          }],
          currencyEmun: 0
        }
      });
    }
  }, [priceSuccess, documentType, checkoutSession]);

  useEffect(() => {
    if (isError) {
      console.error(error);
      // alert("error", error); // Uncomment this line if you want to display the error alert.
    }
  }, [isError, error]);

  useEffect(() => {
    if (priceSuccess) {
      createCharge({
        'chargeRequest': {
          "name": documentTypeEnums[documentType],
          "description": '',
          "pricing_type": 'fixed_price',
          "local_price": {
            "amount": data?.documentPrice.newPrice.toString(),
            "currency": 'USD'
          }
        }
      });
    }
  }, [createCharge, data, documentType, priceSuccess]);

  useEffect(() => {
    if (chargeDataSuccess && createChargeData) {
      setHostedUrl(createChargeData.chargeResponse.data.hosted_url);
    }
  }, [chargeDataSuccess, createChargeData]);

  const options = { ...checkoutSessionData };

  const handleClick = () => {
    if (hostedUrl) {
      window.location.href = hostedUrl;
    }
  };

  return (
    <>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" padding="6" boxShadow="lg" bg="white" h="100vh" w="full">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box>
          <Navbar />
          <VStack paddingBottom="100px" w="80%" h="100%" justify="space-between">
            <VStack>
              <Text fontSize="25px" fontWeight="bold">
                Simple steps to payment with <chakra.span color="Green.100">PhcWorkHub</chakra.span>
              </Text>
            </VStack>
            <VStack border="1px solid" borderColor="LightGrey.100" p="30px" borderRadius="20px" spacing="30px" w="50%" maxW="900px">
              <HStack w="100%" justify="center" p="7px" align="center" bgColor="LightGrey.200">
                <Text fontWeight="bold">Checkout</Text>
                <Image boxSize="30px" src={shoppingCart} />
              </HStack>
              <FormControl>
                <FormLabel>What email would you like it to be sent to?</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EmailIcon color="black" />
                  </InputLeftElement>
                  <Input type="email" placeholder="max.luca@gmail.com" />
                </InputGroup>
              </FormControl>
              {/* <HStack w="100%">
                <Text>Number of copies:</Text>
                <NumberInput size="xs" maxW={16} defaultValue={1} min={0}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack> */}
                <Items documentType={documentType} checkoutDetails={data} price={createChargeData?.chargeResponse.data.pricing.local_price?.amount}/>
              <Checkbox colorScheme="blue" alignSelf="flex-start" defaultChecked>
                Please check to acknowledge our privacy & terms policy
              </Checkbox>
              <Button w="60%" variant="primary" onClick={onOpen}>
                Pay with card
              </Button>
              <Button w="60%" variant="primary" cursor={!hostedUrl ? "not-allowed" : "pointer"} onClick={handleClick} disabled={!hostedUrl}>
                Pay with crypto
              </Button>
            </VStack>
          </VStack>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <div id="checkout">
                  <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Footer />
        </Box>
      )}
    </>
  );
};