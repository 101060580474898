import { toDecimalPlaces } from "./utils"

type MaritalStatusType = "single" | "married"

export const calculateSocialSecurityTax = (wage: number) => {
    /*
        Social security tax withheld is 6.2% of the social security wages
    */
    return toDecimalPlaces(wage * (6.2 / 100),2)
}

export const calculateMedicareTax = (wage: number) => {
    /*
        Medicare tax withheld is 1.45% of the Medicare wages.
    */
    return toDecimalPlaces(wage * (1.45 / 100), 2)
}

export const calculateFedTax = (wage: number, maritalStatus: MaritalStatusType) => {
    switch (maritalStatus) {
        case "married":
            return  toDecimalPlaces(wage * (10 / 100),2)
        case "single":
            return toDecimalPlaces(wage * (13 / 100),2)
    }
}

export const calculateStateTax = (wage: number, maritalStatus: MaritalStatusType) => {
    switch (maritalStatus) {
        case "married":
            return toDecimalPlaces(wage * (6 / 100),2)
        case "single":
            return toDecimalPlaces(wage * (8 / 100),2)
    }
}


