import FormHeading from "../../Components/FormHeading";
import {Box} from "@chakra-ui/react"
import QuoteFrom from "./Forms/QuoteFrom";
import QuoteFor from "./Forms/QuoteFor";
import QuoteItems from "./Forms/QuoteItems";
import BusinessTaxDeduction from "../../Components/Forms/BusinessTaxDeduction";
import React, {useEffect} from "react"
import usePreferredPriceDiscount from "../../../Hooks/usePreferredPriceDiscount";
import {IQuotes} from "../../../Types/Interface/IQuotes";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";
import {setDocumentData} from "../../../Store/Slices";

const QuoteForms = () => {
    const {values} = usePreferredPriceDiscount<IQuotes>()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setDocumentData(values));
    }, [values]);

  return (
      <Box flexBasis={{base: "45%", lg: "60%"}}>
          <FormHeading color={"#1fbc6c"} title={"Create Quotation"} description={"Fill in your information below"}/>
          <QuoteFrom/>
          <QuoteFor/>
          <QuoteItems/>
          <BusinessTaxDeduction/>
      </Box>
  )
}

export default QuoteForms