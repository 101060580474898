import { Box, Button, Checkbox, Flex, Image, Input, Stack, Text } from "@chakra-ui/react";
import { SplitLayout } from "Layouts/SplitLayout";
import Laptop from 'Assets/Images/Laptop.png'
import React from "react";

export const LoginPage = () => {
    return(
        <SplitLayout buttonTitle="Log In">
           
                <Flex  direction={'column'} w={'100%'} minHeight={'100%'} overflow={'hidden'} > 
                    
                    <Stack minH={'100%'} justify={'center'} align={'center'} >
                        <Stack justify={'center'} align={'center'} mb={'30px'}>
                            <Text fontFamily={'Zen Kaku Gothic Antique'} color={'black'} fontSize={'26px'} fontWeight={'700'}>Account Log In</Text>
                            <Text color={'#616161'} textAlign={'center'} fontSize={'14px'}>PLEASE LOGIN TO CONTNUE TO YOU</Text>
                        </Stack>

                        <Box w={'500px'}>
                            <Input border={'1px solid LightGrey'} mb={'10px'} borderRadius={'2px'} placeholder="Email" />
                            <Input border={'1px solid LightGrey'} borderRadius={'2px'} placeholder="Password" />
                        </Box>
                        <Flex w={'500px'} justifyContent={'space-between'} alignItems={'center'} >
                            <Checkbox size={'sm'} fontSize={'12px'} color={'lightgrey'} defaultChecked>Remember Me</Checkbox>
                            <Text fontSize={'12px'} color={'lightgrey'}>Forgot Password?</Text>
                        </Flex>
                       <Button w={'500px'} h={'50px'} variant={'primary'}>LOG IN</Button>
                    </Stack>
             
            </Flex>
            <Flex justifyContent={'flex-end'} alignItems={'center'} w={'100%'}>
                <Image src={Laptop} boxSize={'400px'} />
            </Flex>
            
        </SplitLayout>
    )
}