export const states = [
    { label: "Alaska", value: "Alaska", initial: "AK" },
    { label: "Alabama", value: "Alabama", initial: "AL" },
    { label: "Arkansas", value: "Arkansas", initial: "AR" },
    { label: "Arizona", value: "Arizona", initial: "AZ" },
    { label: "California", value: "California", initial: "CA" },
    { label: "Colorado", value: "Colorado", initial: "CO" },
    { label: "Connecticut", value: "Connecticut", initial: "CT" },
    { label: "District of Columbia", value: "District of Columbia", initial: "DC" },
    { label: "Delaware", value: "Delaware", initial: "DE" },
    { label: "Florida", value: "Florida", initial: "FL" },
    { label: "Georgia", value: "Georgia", initial: "GA" },
    { label: "Hawaii", value: "Hawaii", initial: "HI" },
    { label: "Iowa", value: "Iowa", initial: "IA" },
    { label: "Idaho", value: "Idaho", initial: "ID" },
    { label: "Illinois", value: "Illinois", initial: "IL" },
    { label: "Indiana", value: "Indiana", initial: "IN" },
    { label: "Kansas", value: "Kansas", initial: "KS" },
    { label: "Kentucky", value: "Kentucky", initial: "KY" },
    { label: "Louisiana", value: "Louisiana", initial: "LA" },
    { label: "Massachusetts", value: "Massachusetts", initial: "MA" },
    { label: "Maryland", value: "Maryland", initial: "MD" },
    { label: "Maine", value: "Maine", initial: "ME" },
    { label: "Michigan", value: "Michigan", initial: "MI" },
    { label: "Minnesota", value: "Minnesota", initial: "MN" },
    { label: "Missouri", value: "Missouri", initial: "MO" },
    { label: "Mississippi", value: "Mississippi", initial: "MS" },
    { label: "Montana", value: "Montana", initial: "MT" },
    { label: "North Carolina", value: "North Carolina", initial: "NC" },
    { label: "North Dakota", value: "North Dakota", initial: "ND" },
    { label: "Nebraska", value: "Nebraska", initial: "NE" },
    { label: "New Hampshire", value: "New Hampshire", initial: "NH" },
    { label: "New Jersey", value: "New Jersey", initial: "NJ" },
    { label: "New Mexico", value: "New Mexico", initial: "NM" },
    { label: "Nevada", value: "Nevada", initial: "NV" },
    { label: "New York", value: "NewYork", initial: "NY" },
    { label: "Ohio", value: "Ohio", initial: "OH" },
    { label: "Oklahoma", value: "Oklahoma", initial: "OK" },
    { label: "Oregon", value: "Oregon", initial: "OR" },
    { label: "Pennsylvania", value: "Pennsylvania", initial: "PA" },
    { label: "Rhode Island", value: "Rhode Island", initial: "RI" },
    { label: "South Carolina", value: "South Carolina", initial: "SC" },
    { label: "South Dakota", value: "South Dakota", initial: "SD" },
    { label: "Tennessee", value: "Tennessee", initial: "TN" },
    { label: "Texas", value: "Texas", initial: "TX" },
    { label: "Utah", value: "Utah", initial: "UT" },
    { label: "Virginia", value: "Virginia", initial: "VA" },
    { label: "Vermont", value: "Vermont", initial: "VT" },
    { label: "Washington", value: "Washington", initial: "WA" },
    { label: "Wisconsin", value: "Wisconsin", initial: "WI" },
    { label: "West Virginia", value: "West Virginia", initial: "WV" },
    { label: "Wyoming", value: "Wyoming", initial: "WY" },
];

type TaxCode = {
    code: string;
    description: string;
}

export const taxCodes: TaxCode[] = [
    {
        code: "A",
        description: "Uncollected social security or RRTA tax on tips"
    },
    {
        code: "B",
        description: "Uncollected Medicare tax on tips"
    },
    {
        code: "C",
        description: "Taxable cost of group-term life insurance over $50,000"
    },
    {
        code: "D",
        description: "Elective deferrals under a section 401(k) cash or deferred arrangement (plan)"
    },
    {
        code: "E",
        description: "Elective deferrals under a section 403(b) salary reduction agreement"
    },
    {
        code: "F",
        description: "Elective deferrals under a section 408(k)(6) salary reduction SEP"
    },
    {
        code: "G",
        description: "Elective deferrals and employer contributions to a section 457(b) deferred compensation plan"
    },
    {
        code: "H",
        description: "Elective deferrals to a section 501(c)(18)(D) tax-exempt organization plan"
    },
    {
        code: "J",
        description: "Nontaxable sick pay"
    },
    {
        code: "K",
        description: "20% excise tax on excess golden parachute payments"
    },
    {
        code: "L",
        description: "Substantiated employee business expense reimbursements"
    },
    {
        code: "M",
        description: "Uncollected social security or RRTA tax on taxable cost of groupterm life insurance over $50,000"
    },
    {
        code: "N",
        description: "Uncollected Medicare tax on taxable cost of group-term life insurance over $50,000"
    },
    {
        code: "P",
        description: "Excludable moving expense reimbursements paid directly to a member of the U.S. Armed Forces"
    },
    {
        code: "Q",
        description: "Nontaxable combat pay."
    },
    {
        code: "R",
        description: "Employer contributions to your Archer MSA."
    },
    {
        code: "S",
        description: "Employee salary reduction contributions under a section 408(p) SIMPLE plan"
    },
    {
        code: "T",
        description: "Adoption benefits"
    },
    {
        code: "V",
        description: "Income from exercise of nonstatutory stock option(s)"
    },
    {
        code: "W",
        description: "Employer contributions to a health savings account."
    },
    {
        code: "Y",
        description: "Deferrals under a section 409A nonqualified deferred compensation plan"
    },
    {
        code: "Z",
        description: "Income under a nonqualified deferred compensation plan that fails to satisfy section 409A"
    },
    {
        code: "AA",
        description: "Designated Roth contributions under a section 401(k) plan"
    },
    {
        code: "BB",
        description: "Designated Roth contributions under a section 403(b) plan"
    },
    {
        code: "DD",
        description: "Cost of employer-sponsored health coverage. "
    },
    {
        code: "EE",
        description: "Designated Roth contributions under a governmental section 457(b) plan"
    },
    {
        code: "FF",
        description: "Permitted benefits under a qualified small employer health reimbursement arrangement"
    },
    {
        code: "GG",
        description: "Income from qualified equity grants under section 83(i)"
    },
    {
        code: "HH",
        description: "Aggregate deferrals under section 83(i) elections as of the close of the calendar year"
    },
    {
        code: "II",
        description: "Medicaid waiver payments excluded from gross income under Notice 2014-7"
    },
]