import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    HStack,
    Box, Text
} from "@chakra-ui/react";
// import usePriceCalculations from "Hooks/usePriceCalculation";
// import { useToast } from "Hooks/useToast";
import { documentTypeEnums } from "Types/Enums/documentTypeEnums";
import { IDocumentPrice } from "Types/Interface/IPricing";
import React from "react";

interface IItemsProps {
    checkoutDetails?: IDocumentPrice
    documentType: number
    price?: string | undefined
}

export const Items = ({ price, documentType, checkoutDetails }: IItemsProps) => {
    // const { discount, subtotal, total } = usePriceCalculations(checkoutDetails);


    return (
        <>
            <Accordion w={'100%'} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box fontWeight={'bold'} as='span' flex='1' textAlign='left'>
                                Order Summary
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <HStack mb={'10px'} w={'100%'} justify={'space-between'}>
                            <Text>1 items in cart</Text>
                            <Text>{price ?? checkoutDetails?.documentPrice.newPrice}</Text>
                        </HStack>
                        <Text>Type: <span color={'LightGrey.100'}>{documentTypeEnums[documentType]}</span></Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {/*<Accordion w={'100%'} allowToggle>*/}
            {/*    <AccordionItem>*/}
            {/*        <h2>*/}
            {/*            <AccordionButton>*/}
            {/*                <Box fontWeight={'bold'} as='span' flex='1' textAlign='left'>*/}
            {/*                    Checkout Summary*/}
            {/*                </Box>*/}
            {/*                <AccordionIcon />*/}
            {/*            </AccordionButton>*/}
            {/*        </h2>*/}
            {/*            <AccordionPanel pb={4}>*/}
            {/*                <HStack mb={'10px'} w={'100%'} justify={'space-between'}>*/}
            {/*                    <Text>Subtotal</Text>*/}
            {/*                    <Text>${subtotal}</Text>*/}
            {/*                </HStack>*/}
            {/*                <HStack mb={'10px'} w={'100%'} justify={'space-between'}>*/}
            {/*                    <Text>Discount</Text>*/}
            {/*                    <Text>${discount}</Text>*/}
            {/*                </HStack>*/}
            {/*                <HStack mb={'10px'} w={'100%'} justify={'space-between'}>*/}
            {/*                    <Text>Tax</Text>*/}
            {/*                    <Text>${checkoutDetails?.documentPrice.salesTax}</Text>*/}
            {/*                </HStack>*/}
            {/*                <Divider my={'30px'} border={'1px solid LightGrey.100'} />*/}
            {/*                <HStack mb={'10px'} w={'100%'} justify={'space-between'}>*/}
            {/*                    <Text>Total</Text>*/}
            {/*                    <Text>${total}</Text>*/}
            {/*                </HStack>*/}
            {/*            </AccordionPanel>*/}
            {/*    </AccordionItem>*/}
            {/*</Accordion>*/}
        </>
    )
}