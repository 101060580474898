import {Box, Center, Flex, Image, Text} from "@chakra-ui/react";
import confidenceIcon from "../../../Assets/Images/Confidence.png";
import designIcon from "../../../Assets/Images/Draw.png";
import actionIcon from "../../../Assets/Images/Confidence.png";
import chooseUsHeading from "../../../Assets/Images/ChooseUsHeading.png"
import {theme} from "../../../Theme/Theme";


type ReasonProps = {
    icon: string
    reason: string,
    explanation: string,
}

const reasons: ReasonProps[] = [
    {
        icon: confidenceIcon,
        reason: "Confidence",
        explanation: "We use the most recent and accurate information available so that you can have full confidence in our documents!."
    },
    {
        icon: designIcon,
        reason: "Design with real data",
        explanation: "Preview and  Seamlessly edit and manage your documents with our intuitive interface."
    },
    {
        icon: actionIcon,
        reason: "Fastest way to take action",
        explanation: "Elevate your documents with contemporary templates featuring clean lines and minimalist aesthetics."
    }
]


const ChooseUsSection = () => {
  return (
      <Box px={"138px"} py={"98px"}>
          <Center mb={"64px"}>
              <Image src={chooseUsHeading}/>
          </Center>
          <Flex gap={"24px"} justifyContent={"space-between"}>
              {
                  reasons.map((reason) => <Reason key={reason.reason}  explanation={reason.explanation} reason={reason.reason} icon={reason.icon}/>)
              }
          </Flex>
      </Box>
  )
}

const Reason = ({icon, reason, explanation}: ReasonProps) => {
  return (
      <Flex direction={"column"} align={"center"} >
          <Image src={icon}/>
          <Text fontSize={"28px"} color={theme.onSurface} fontWeight={"bold"} my={"28px"}>
              {reason}
          </Text>
          <Text fontSize={"24px"} textAlign={"center"}>
              {explanation}
          </Text>
      </Flex>
  )
}

export default ChooseUsSection