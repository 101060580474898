export const Button = {
	borderRadius: "1.5",
	sizes: {
		lg: {
			h: '48px',
			fontSize: 'md',
			px: '24px'
		},
		md: {
			h: '40px',
			fontSize: 'sm',
			px: '16px'
		},
		sm: {
			h: '32px',
			fontSize: 'sm',
			px: '12px'
		},
		xs: {
			h: '24px',
			fontSize: 'xs',
			px: '8px'
		}
	},
	variants: {
		primary: {
			bg: 'Green.100',
			color: 'white',
			p: '3',
			px: '4',
			borderWidth: 1,
			borderRadius: 0,
			borderColor: 'Green.100',
			fontSize: 'sm',
			_disabled: {
				bg: 'lightGrey',
				color: 'black',
				borderColor: '',
			},

			_loading: {
				bg: 'darkCoral',
				_hover: {
					bg: 'darkCoral',
				},
			},

			_hover: {
				bg: 'white',
				color: 'Green.100',
				borderColor: 'Green.100',
				_disabled: {
					bg: 'lightGrey',
					color: 'brand.black',
					borderColor: 'brand.black',
				},
			},
		},
		secondary: {
			bg: 'white',
			color: 'Green.100',
			p: '3',
			px: '4',
			borderWidth: 1,
			borderRadius: 0,
			borderColor: 'Green.100',
			fontSize: 'sm',
			_disabled: {
				bg: 'lightGrey',
				color: 'black',
				borderColor: '',
			},
	
			_loading: {
				bg: 'darkCoral',
				_hover: {
					bg: 'darkCoral',
				},
			},
	
			_hover: {
				bg: 'Green.100',
				color: 'white',
				borderColor: 'Green.100',
				_disabled: {
					bg: 'lightGrey',
					color: 'brand.black',
					borderColor: 'brand.black',
				},
			},
		},
		solid: {
			bg: 'primary',
			color: 'onPrimary',
			_hover: {
				bg: 'Green.100',
				color: 'onPrimary'
			}
		},
		outline: {
			bg: "transparent",
			color: "primary",
			borderColor: 'primary',
			borderWidth: 'px',
			_hover: {
				bg: "primary",
				color: 'onPrimary'
			}
		},
		rounded: {
			bg: "primary",
			color: "onPrimary",
			borderRadius: "30px",
			_hover: {
				bg: "Green.100",
				color: "onPrimary"
			}
		}
	}
};
