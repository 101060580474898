import BusinessInfo from "../../../Components/Forms/BusinessInfo";

const prefix = "for."
const addressPrefix = `${prefix}address.`

const ReceiptTo = () => {
    return (
        <BusinessInfo formHeading={"Customer's Details"} currentStep={2} steps={4} prefix={prefix} addressPrefix={addressPrefix}/>
    )
}

export default ReceiptTo