import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import arc from "../../../Assets/Images/Arc.png"
import { theme } from "../../../Theme/Theme";
import {
    InstantSearch,
    SearchBox,
    Hits,
    Highlight,
} from 'react-instantsearch-dom';
import searchClient from '../../../config';
import { AlgoliaHit } from '../../../Types/Interface/AlgoliaHit';

const ProductivitySection = () => {
    return (
        <Flex direction={"column"} pt={"115px"} w={'100%'} overflow={"clip"} h={"100vh"} pb={"40px"} align={"center"} background={theme.primaryFixed} px={"40px"}>
            <Flex direction={"column"} align={"center"} w={"798px"}>
                <Text fontSize={"6xl"} fontWeight={"bold"} mb={"30px"}>
                    PhcWorkHub
                </Text>
                <Image w={479} objectFit={"contain"} src={arc} />
                <Box h={50}></Box>
                <Text fontSize={"xl"} mb={"24px"} textAlign={"center"}>
                    Effortlessly create accurate and personalized documents for your business needs in minutes
                </Text>
                <InputGroup background={"white"} borderRadius={"full"} overflow={"clip"}>
                    <Input focusBorderColor={"primary"} borderRadius={"full"} px={"16px"} placeholder={"Search for forms"} />
                    <InputRightElement color={"primary"} mx={"16px"} p={"8px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </InputRightElement>
                </InputGroup>
                <Text my={"48px"}>Search for forms you want to create</Text>
                <Button w={"413px"} background={"primary"} borderRadius={"full"} color={theme.onSecondary}>
                    Create Unlimited forms
                </Button>
            </Flex>
        </Flex>
    )
}

export default ProductivitySection