import {Checkbox, CheckboxProps, FormControl, FormLabel} from "@chakra-ui/react";

type FormikCheckBoxProps = CheckboxProps & {
    text?: string;
    size?: string;
    label?: string;
}


const FormikCheckBox = ({label, flexBasis, name, onChange, isChecked, size, text, ...props}: FormikCheckBoxProps) => {
  return (
      <FormControl mt={"20px"} flexBasis={flexBasis}>
          {
              (
                  !text && (
                      <FormLabel>
                          {label}
                      </FormLabel>
                  )
              )
          }
          <div>
              <Checkbox isChecked={isChecked} onChange={onChange} size={size} colorScheme={"green"} {...props}>
                  {text}
              </Checkbox>
              {/*{meta.touched &&*/}
              {/*    meta.error && <div className="error">{meta.error}</div>}*/}
          </div>
      </FormControl>
  )
}

export default FormikCheckBox