import { extendTheme } from '@chakra-ui/react';
import { Button } from './Components/Button';

export const theme = extendTheme({
	colors: {
		white: '#ffffff',
		black: '#000000',
		textBlue: '#4C63ED',
		primary: '#1FBC6C',
		onPrimary: "#ffffff",
		transparent: "#00000000",
		Green: {
			100: '#86BF97',
			200: '#699275',
			300: '#F0F7F3'
		},
		LightGrey: {
			100:'#9E9E9E',
			200: '#EBEDF2',
			300: '#5E6470',
			400: '#F9FAFC'
		},
	},
	fonts: {
		heading: 'Road Rage',
		body: 'Roboto',
	},
	components: {
		Button
	}
});
