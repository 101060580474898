import {useAppDispatch} from "./useAppDispatch";
import {IDocumentBase} from "../Types/Interface/IQuotes";
import {useToast} from "./useToast";
import {LogoUpload} from "../Api";
import {setLogo} from "../Store/Slices";


const useHandleUploadLogo = <T extends IDocumentBase>(values: T) => {
    const dispatch = useAppDispatch()
    const {alert} = useToast();
    return async (image: File) => {
        if (values.from.name.length === 0 || values.from.email.length === 0) {
            alert("error", "Please fill Company name and email")
        } else {
            const formData = new FormData();
            formData.append('logo', image);
            formData.append('companyName', values.from.name);
            formData.append('companyEmail', values.from.email);

            try {
                const response = await LogoUpload(formData);
                dispatch(setLogo(response.url))
                alert('success', 'Logo uploaded!');
            } catch (error) {
                alert('error', 'There was an error uploading your logo');
            }
        }
    }
}

export default useHandleUploadLogo;