import EmployerInfo from "./Forms/EmployerInfo";
import FormsLayout from "../../../Layouts/FormsLayout";
import EmployeeInfo from "./Forms/EmployeeInfo";
import FormHeading from "../../Components/FormHeading";
import { selectEmployees } from "../../../Store/Slices/w2Slice";
import { useAppSelector } from "../../../Hooks/useAppSelector";
import W2BasicInfo from "./Forms/W2BasicInfo";
import { IEmployee } from "../../../Types/Interface/IW2";

const W2Forms = () => {
    return (
        <FormsLayout>
            <FormHeading title={"Create W2"} description={"Fill in your information below"} />
            <W2BasicInfo />
            <EmployerInfo />
            <EmployeesInfo />
        </FormsLayout>
    )
}

const EmployeesInfo = () => {
    const employees: IEmployee[] = useAppSelector(selectEmployees)

    return (
        <>
            {
                employees.map((employee, index) => <EmployeeInfo employee={employee} key={index} index={index} />)
            }
        </>
    )
}

export default W2Forms;