import {Flex} from "@chakra-ui/react";
import AppLayout from "../../Layouts/AppLayout";
import PayStubForms from "./Components/PayStubForms";
import PayStubPreviewSection from "./Components/PayStubPreviewSection";

const PayStub = () => {
  return (
      <AppLayout>
      {/*  Forms and Preview*/}
          <Flex w={"full"} px={"50px"} py={"50px"} gap={"64px"} pos={"relative"}>
              <PayStubForms/>
              <PayStubPreviewSection/>
          </Flex>
      </AppLayout>
  )
}


export default PayStub