import {Box} from "@chakra-ui/react";

const FormsLayout = ({children}: {children?: React.ReactNode}) => {
  return (
      <Box flexBasis={{base: "65%", md: "60%"}}>
          {children}
      </Box>
  )
}

export default FormsLayout