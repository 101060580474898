import {SelectProps} from "@chakra-ui/react";
import {FormikInputProps} from "./types";
import {ReactNode} from "react";
import { useField } from "formik";
import DropSelect from "../Inputs/DropSelect";

export type FormikSelectProps = FormikInputProps & SelectProps & {
    children: ReactNode;
}

const FormikSelect = ({name, children, ...props}: FormikSelectProps) => {
    const [field, meta] = useField(name);

  return (
          <div>
              <DropSelect {...field} {...props}>
                  {
                      children
                  }
              </DropSelect>
              {meta.touched &&
               meta.error && <div className="error">{meta.error}</div>}
          </div>
  )
}

export default FormikSelect