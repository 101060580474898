import React from "react"
import AppLayout from "../../Layouts/AppLayout";
import {useAppSelector} from "../../Hooks/useAppSelector";
import {select1099NecState} from "../../Store/Slices/1099NecSlice";
import NecForm from "./Components/NecForm";

const F1099Nec = () => {
    const data = useAppSelector(select1099NecState)

  return (
      <AppLayout isForm>
          <NecForm data={data}/>
      </AppLayout>
  )
}

export default F1099Nec