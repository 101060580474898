import React from "react";
import {useFormikContext} from "formik";
import {IQuotes} from "../../../../Types/Interface/IQuotes";
import BusinessInfo from "../../../Components/Forms/BusinessInfo";
import useHandleUploadLogo from "../../../../Hooks/useHandleUploadLogo";

const prefix = "from."
const addressPrefix = `${prefix}address.`

const QuoteFrom = () => {
    const {values} = useFormikContext<IQuotes>()
    const handleUploadLogo = useHandleUploadLogo(values)

    return (
        <BusinessInfo
            formHeading={"Quotation from"}
            currentStep={1}
            prefix={prefix}
            addressPrefix={addressPrefix}
            handleLogoUpload={handleUploadLogo}
            hasWebsiteField steps={3}/>
    )
}


export default QuoteFrom