import {FormControl, FormLabel, Input, Flex} from "@chakra-ui/react";
import {memo} from "react";
import { FormikInputProps } from "../Formik/types";

const TextInput = memo(
    function({label, flexBasis, name, formik, isRequired, ...props}: FormikInputProps) {
        return (
            <FormControl mt={"20px"} h={'full'} isRequired={isRequired} flexBasis={flexBasis}>
                <Flex direction={"column"} justifyContent={"space-between"}>
                    <FormLabel>
                        {label}
                    </FormLabel>
                    <Input name={name} focusBorderColor='primary' {...props} />
                </Flex>
            </FormControl>
        )
    }
)

export default TextInput