import {Box, Flex} from "@chakra-ui/react";
import FormikInput from "../Formik/FormikInput";
import React from "react";
import BusinessFormsHeading from "./BusinessFormsHeading";

const BusinessTaxDeduction = () => {
  return (
      <Box mb={"32px"}>
          <BusinessFormsHeading title={"Add Deduction"}/>
          <Flex alignItems={"center"} gap={"20px"} justifyContent={"space-between"}>
              <FormikInput flexBasis={"40%"} label={"Tax %"} name={"taxAmount"} type={"number"} />
              <FormikInput flexBasis={"20%"} label={"Discount %"} name={"discountPercentage"} type={"number"}/>
              <FormikInput flexBasis={"20%"} label={"Discount Amount"} name={"discountAmount"} type={"number"}/>
          </Flex>
      </Box>
  )
}

export default BusinessTaxDeduction