export enum documentTypeEnums {
    default = 0,
    Quotes = 1,
    Receipt = 2,
    Paystub = 3,
    W2 = 4,
    F1099 = 5,
}

export enum FormType {
    Quotes = "Quotes",
    Receipt = "Receipt",
    Paystub = "Paystub",
    W2 = "W2",
    F1099 = "F1099",
    F1099Nec = "F1099Nec",
}