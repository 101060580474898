import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {I1099Nec} from "../../Pages/1099Nec/validator";
import {RootState} from "../index";


const initial1099State: I1099Nec = {
    info: {
        email: "",
        formCount: 1,
        year: new Date().getFullYear().toString(),
        accept: false,
        agree: false
    },
    payer: {
        tin: "",
        name: "",
        address: {
          stateAddress: "",
          state: "",
          zipcode: "",
          city: ""
        }
    },
    recipients: [
        {
            ssn: "",
            name: "",
            address: {
                stateAddress: "",
                state: "",
                zipcode: "",
                city: ""
            },
            options: {
                nonEmployeeCompensation: null,
                payersNo: null,
                income: null,
                incomeTax: null,
                stateTax: null,
                directSales: false,
                accountNumber: null,
                stateIncome: null,
            }
        }
    ]
}

const f1099NecSlice = createSlice({
    name: "f1099NecSlice",
    initialState: initial1099State,
    reducers: {
        set1099NecState: (state, action: PayloadAction<I1099Nec>) => {
            const data = action.payload
            state.recipients = data.recipients
            state.payer = data.payer
            state.info = data.info
        },
        resetNecState: () => {
            return initial1099State
        }
    }
})

export const select1099NecState = (state: RootState) => state.f1099Nec

export const {set1099NecState, resetNecState} = f1099NecSlice.actions

export default f1099NecSlice.reducer