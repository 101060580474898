type Env = 'production' | 'development' | 'localhost';
type currency = 'USD' | 'GBP';
// type style = 'currency';

export const getAppEnv: () => Env = () => {
    return process.env.REACT_APP_ENVIRONMENT as Env || "develop"
}

export const getStripeKey = () => {
    return getAppEnv() === "production" ? process.env.REACT_APP_STRIPE_PROMISE_PROD : process.env.REACT_APP_STRIPE_PROMISE_DEV
}

export const currencyFormatter = (currency: currency, value: number | string) => {
    const newVal = typeof value === 'string' ? Number(value) : value;
    return Intl.NumberFormat('default', { style: 'currency', currency }).format(newVal);
}

export const us_LocaleDateString = (date: string) => {
    const formatedDate = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        day: '2-digit',
        month: "2-digit"
    })
    return formatedDate === "Invalid Date" ? "" : formatedDate
}

export const toDecimalPlaces = (value: number, decimalPlaces: number) => {
    return +(value.toFixed(decimalPlaces))
}