import {useFormik} from "formik";
import {f1099NecSchema, I1099Nec} from "../validator";
import {Flex} from "@chakra-ui/react";
import FormsLayout from "../../../Layouts/FormsLayout";
import FormHeading from "../../Components/FormHeading";
import NecBasicInfo from "./NecBasicInfo";
import NecPayerForm from "./NecPayerForm";
import NecRecipientsForm from "./NecRecipientsForm";
import NecPreview from "./NecPreview";
import React from "react";
import {setDocumentType, setFormType} from "../../../Store/Slices";
import {documentTypeEnums, FormType} from "../../../Types/Enums/documentTypeEnums";
import {AppRoutes} from "../../../Routes/AppRoutes";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../../Hooks/useToast";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";

const NecForm = ({data}: {data: I1099Nec}) => {
    const navigate = useNavigate();
    const { alert } = useToast()
    const dispatch = useAppDispatch()
    const {handleSubmit, handleChange, values,  setFieldValue} = useFormik<I1099Nec>({
        initialValues: data,
        validationSchema: f1099NecSchema,
        onSubmit: (values, formikHelpers) => {
            formikHelpers.setSubmitting(true)
            formikHelpers.validateForm().then(r => {
                if (r.recipients){
                    alert("error", r.recipients)
                }
                if (r.payer?.address?.state){
                    alert("error", r.payer?.address?.state)
                }
            })
            dispatch(setDocumentType(documentTypeEnums.F1099))
            dispatch(setFormType(FormType.F1099Nec))
            if (!values.info.agree || !values.info.accept) {
                alert("error", "Accept our TOS and agree to not use it for illegal activities")
            } else {
                navigate(AppRoutes.checkout);
            }
            formikHelpers.setSubmitting(false)
        },
    })

  return (
      <form onSubmit={handleSubmit}>
          <Flex w={"full"} direction={{base: "column", md: "row"}} px={"50px"} py={"50px"}
                gap={"32px"} pos={"relative"}>
              <FormsLayout>
                  <FormHeading title={"Create 1099 NEC"} description={"Fill in your information below"}/>
                  <NecBasicInfo handleChange={handleChange} info={values.info}/>
                  <NecPayerForm handleChange={handleChange} payer={values.payer}/>
                  <NecRecipientsForm setValues={setFieldValue} handleChange={handleChange}
                                     recipients={values.recipients === undefined ? [] : values.recipients}/>
              </FormsLayout>
              <NecPreview setValues={setFieldValue} values={values}/>
          </Flex>
      </form>
  )
}

export default NecForm