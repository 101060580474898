import {Box, Text} from "@chakra-ui/react";
import React from "react";

const WaterMark = ({font, left}: {font?: string, left?: string}) => {
    return (
        <Box pos={"absolute"} transform={"rotate(-60deg)"} left={left ? left : "-100px"} top={"320px"}>
            <Text fontSize={font? font : "212px"} fontWeight={"bold"} color={"#00000033"}>
                SAMPLE
            </Text>
        </Box>
    )
}


export default WaterMark