import AppLayout from "../../Layouts/AppLayout";
import {Flex} from "@chakra-ui/react";
import QuoteForms from "./Components/QuoteForms";
import QuotePreview from "./Components/QuotePreview";
import {Form, Formik} from "formik";
import {IAddress, IQuotes, ISender} from "../../Types/Interface/IQuotes";
import {useAppSelector} from "../../Hooks/useAppSelector";
import {setDocumentData, setDocumentType} from "../../Store/Slices";
import {documentTypeEnums} from "../../Types/Enums/documentTypeEnums";
import {AppRoutes} from "../../Routes/AppRoutes";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../Hooks/useToast";
import React from "react"



const initialAddressValues: IAddress = {
    addressLine1: "", addressLine2: "", city: "", country: "", id: 0, postalCode: "", regionOrState: ""
}

const initialSenderValues: ISender = {
    address: initialAddressValues, email: "", id: 0, name: "", phoneNumber: "", websiteUrl: "", logo: ""
}

const initialReceiver: ISender = {
    address: initialAddressValues, email: "", id: 0, name: "", phoneNumber: "", websiteUrl: "",
}

const initialQuoteValues: IQuotes = {
    discountAmount: 0,
    discountPercentage: 0,
    email: "",
    for: initialReceiver,
    from: initialSenderValues,
    id: 0,
    lineItems: [
        { id: 0, description: "", lineTotal: 0, quantity: 0, rate: 0 },
    ],
    logoUrl: "",
    quoteHtmlString: "",
    taxAmount: 0
}



const Quotes = () => {
    const navigate = useNavigate();
    const {alert} = useToast()
    const data = useAppSelector((state) => state.document.documentData) as IQuotes;
    const dispatch = useAppDispatch();

    const handleSubmit = (values: IQuotes,) => {
        dispatch(setDocumentData(values));
        dispatch(setDocumentType(documentTypeEnums.Quotes))
        if (!values.agree || !values.accept ){
            alert("error", "Please agree or accept to our Terms")
        } else if(Object.keys(data).length > 0){
            navigate(AppRoutes.checkout);
        }
    }
  return (
      <AppLayout isForm>
          <Formik<IQuotes> initialValues={data === null ? initialQuoteValues : data } onSubmit={handleSubmit}>
              {
                  () => (
                      <Form>
                          <Flex w={"full"} direction={{base: "column", lg: "row"}} px={"50px"} py={"50px"} gap={"32px"} pos={"relative"}>
                              <QuoteForms/>
                              <QuotePreview/>
                          </Flex>
                      </Form>
                  )
              }
          </Formik>
      </AppLayout>
  )
}

export default Quotes