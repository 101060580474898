import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReceipt } from 'Types/Interface/IReceipt'

interface IInitialReceiptState{
    receipt: IReceipt;
    logo: string;
}

const initialState: IInitialReceiptState = {
    receipt: {} as IReceipt,
    logo: ''
}

export const receiptSlice = createSlice({
    name:'receipt',
    initialState,
    reducers: {
        setReceipt: ( state, action:PayloadAction<IReceipt>) => {
            state.receipt = action.payload
        },
        setReceiptLogo: (state, action:PayloadAction<string>) => {
            state.logo = action.payload
        }
    }
})

export const {setReceipt, setReceiptLogo} = receiptSlice.actions;
export default receiptSlice.reducer