import {useMemo} from "react";

const useFormCounter = () => {
    const { keys: formCounts } = useMemo(() => {
        const keys: number[] = Array.from(Array(4).keys())
        return { keys }
    }, []);
    return formCounts
}

export default useFormCounter