import { VStack, Button, Box, Image, Text } from "@chakra-ui/react";
import { useAppSelector } from "Hooks/useAppSelector";
import { useGetPriceByDocumentTypeQuery, useCreateQuotesMutation, useCreateReceiptMutation } from "Services";
import React, { useEffect, useRef, useState } from "react";
import Logo from 'Assets/Svg/Logo.svg';
import { QuotesTemplate } from "Pages/Templates/QuotesTemplate";
import { useReactToPrint } from "react-to-print";
import { ReceiptTemplate } from "Pages/Templates/ReceiptTemplate";
import {documentTypeEnums, FormType} from "Types/Enums/documentTypeEnums";
import { checkoutMode } from "Types/Enums/checkoutModeEnum";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../Routes/AppRoutes";
import {useAppDispatch} from "../../Hooks/useAppDispatch";
import {resetDocumentState} from "../../Store/Slices";
import useFillW2 from "../../Hooks/useFillW2";
import {resetW2, selectW2} from "../../Store/Slices/w2Slice";
import useFill1099Misc from "../../Hooks/useFill1099Misc";
import {reset1099Misc, select1099State} from "../../Store/Slices/1099MiscSlice";
import {resetNecState, select1099NecState} from "../../Store/Slices/1099NecSlice";
import useFill1099Nec from "../../Hooks/useFill1099Nec";

export const Return = () => {
    const [status, setStatus] = useState(false);
    const documentType = useAppSelector((state) => state.document.documentType);
    const formType = useAppSelector((state)=> state.document.formType)
    const { data: documentPrice } = useGetPriceByDocumentTypeQuery(documentType);
    const [createQuotes, { data: quotesData, isSuccess: isQuotesSuccess, isLoading:isCreateQuotesLoading }] = useCreateQuotesMutation();
    const [createReceipt, { data: receiptData, isSuccess: isReceiptSuccess, isLoading:isCreateReceiptLoading }] = useCreateReceiptMutation();
    const amount = documentPrice?.documentPrice.newPrice;
    const document = useAppSelector((state) => state.document.documentData);
    const w2 = useAppSelector(selectW2)
    const componentRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const printDoc =  useReactToPrint({
        content: () => componentRef.current
    });
    const {data: w2data} = useFillW2(w2)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const f1099Misc = useAppSelector(select1099State)
    const {data: f1099MiscData} = useFill1099Misc(f1099Misc)
    const f1099Nec = useAppSelector(select1099NecState)
    const {data: f1099NecData} = useFill1099Nec(f1099Nec)

    const handlePrint = () => {
        if (documentTypeEnums.Receipt === documentType || documentTypeEnums.Quotes === documentType){
            printDoc()

        }
        if (documentTypeEnums.W2 === documentType){
            const pdfUrl = URL.createObjectURL(w2data as Blob)
            if (anchorRef.current){
                anchorRef.current.href = pdfUrl
                anchorRef.current.download = "w2"
                anchorRef.current.click()
                dispatch(resetW2())
            }
        }
        if (documentTypeEnums.F1099 === documentType){
            let pdfUrl
            let formName
            if (FormType.F1099Nec === formType){
                pdfUrl = URL.createObjectURL(f1099NecData as Blob)
                formName = "1099 nec"
            } else {
                pdfUrl = URL.createObjectURL(f1099MiscData as Blob)
                formName = "1099 misc"
            }
            if (anchorRef.current){
                anchorRef.current.href = pdfUrl
                anchorRef.current.download = formName
                anchorRef.current.click()
                dispatch(reset1099Misc())
                dispatch(resetNecState())
            }
        }
        dispatch(resetDocumentState())
        navigate(AppRoutes.home)
    }

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        if (sessionId && amount && documentType !== undefined) {
            const payload = { checkoutSessionId: sessionId, checkoutAmount: amount, checkoutMode: checkoutMode.stripe, body: document };

            if (documentType === documentTypeEnums.Quotes) {
                createQuotes(payload);
            } else if (documentType === documentTypeEnums.Receipt) {
                createReceipt(payload);
            }
        }
    }, [createQuotes, createReceipt, amount, documentType, document]);

    useEffect(() => {
        if (isQuotesSuccess && quotesData) {
            setStatus(quotesData.isSuccess);
        }
        if (isReceiptSuccess && receiptData) {
            setStatus(receiptData.isSuccess);
        }
    }, [isQuotesSuccess, quotesData, isReceiptSuccess, receiptData]);

    const renderTemplate = () => {
        switch (documentType) {
            case documentTypeEnums.Quotes:
                return <QuotesTemplate hasPaid={true} values={document!} ref={componentRef} logoUrl={document?.from.logo} />;
            case documentTypeEnums.Receipt:
                return <ReceiptTemplate values={document!} logoUrl={document?.from.logo} ref={componentRef} />;
            default:
                return null;
        }
    };

    if (isCreateQuotesLoading || isCreateReceiptLoading) {
        return (
            <Box padding={'100px'}>
                <VStack align={'flex-start'}>
                    <Image src={Logo} />
                    <Text fontWeight={'700'} fontSize={'36px'}>Processing your document...</Text>
                    <Text fontSize={'20px'} color={'#00000066'}>Please wait while we generate your document.</Text>
                </VStack>
            </Box>
        );
    }

    return (
        <>
             <Box padding={'100px'}>
                <VStack align={'flex-start'}>
                    <Image src={Logo} />
                    {status &&  <Text fontWeight={'700'} fontSize={'36px'}>Nicely Done. Your document has been generated</Text>}
                    <Text fontSize={'20px'} color={'#00000066'}>We&apos;ve sent it to the email address you provided.</Text>
                    <Button onClick={handlePrint} bg={'primary'} border={'1px solid black'} borderRadius={'5px'}>Download PDF</Button>
                </VStack>
                <Box position="absolute" top="-9999px" left="-9999px">
                    {renderTemplate()}
                    <a href={'#'} ref={anchorRef}>Download file</a>
                </Box>
            </Box>
        </>
    );
};