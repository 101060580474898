import FormPreviewLayout from "../../../Layouts/FormPreviewLayout";
import {ReceiptTemplate} from "../../Templates/ReceiptTemplate";
import { useFormikContext} from "formik";
import {IReceipt} from "../../../Types/Interface/IReceipt";
import {useAppSelector} from "../../../Hooks/useAppSelector";
import {Box} from "@chakra-ui/react";
import FieldCheckBox from "../../Components/Formik/FieldCheckBox";
import FormPreviewBar from "../../Components/FormPreviewBar";
import {setDocumentType} from "../../../Store/Slices";
import { documentTypeEnums, FormType } from "../../../Types/Enums/documentTypeEnums";
import {useAppDispatch} from "../../../Hooks/useAppDispatch";


const ReceiptPreview = () => {
    const {values} = useFormikContext<IReceipt>()
    const logo = useAppSelector((state) => state.document.logo)
    const dispatch = useAppDispatch()
  return (
      <FormPreviewLayout flexBasis={{base: "50%", lg: "60%"}}>
          <ReceiptTemplate values={values} logoUrl={logo}/>
          <Box borderBottom={"1px"} pb={"24px"} mt={"24px"} mb={"24px"} borderBottomColor={"#1fbc6c80"}>

              <FieldCheckBox name={"agree"}>
                  I agree not to use this site for illegal purposes
              </FieldCheckBox>
              <FieldCheckBox name={"accept"}>
                  I accept our Term of Use and Privacy Policy
              </FieldCheckBox>
          </Box>
          <FormPreviewBar values={values} setDocumentType={() => dispatch(setDocumentType(documentTypeEnums.Receipt))} background={!(values.accept && values.agree) ? "grey" : "primary"} disabled={!(values.accept && values.agree)} formType={FormType.Receipt} />

      </FormPreviewLayout>
  )
}

export default ReceiptPreview