import React, {forwardRef, PropsWithChildren, useEffect, useRef, useState} from "react";
import html2canvas from "html2canvas";
import {Box, Center, Image, Spinner} from "@chakra-ui/react";
import {createPortal} from "react-dom";
import WaterMark from "../Templates/WaterMark";
import {useDebouncedCallback} from "use-debounce";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { setTemplateImagePreview } from "Store/Slices/templateSlice";

const TemplateWrapper = ({children}: {children: React.ReactNode}) => {
    const templateRef = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState<string | null>()
    const dispatch = useAppDispatch()
    const debounce = useDebouncedCallback(
        ()=>{
            const template = templateRef.current
            if (template) {
                template.hidden = false
                template.style.visibility = "visible"

                // converts the template to an image
                html2canvas(template).then(canvas => {
                    setImage(canvas.toDataURL('image/png'))
                    dispatch(setTemplateImagePreview(canvas.toDataURL('image/png')))
                    template.style.visibility = "hidden"
                    template.hidden = true
                })
            }
            setLoading(false)
        }, 1000
    )


    useEffect(() => {
        setLoading(true)
       debounce()
    }, [children, debounce])

  return (
      <Box>
          {
              // Renders the template in the dom inside the body
              createPortal(<Template ref={templateRef}>{children}</Template>, document.body)
          }
          <Box h={loading ? "500px" : ""} w={"500px"}>
          {
              // renders the template as an image
              loading ? <Center h={"full"}><Spinner/></Center> : image != null ? <Image src={image} w={"500px"} objectFit={'contain'}/> : <></>
          }
          </Box>
      </Box>
  )
}

const Template = forwardRef<HTMLDivElement, PropsWithChildren>((props, ref) => {
    return (
        <Box  pos={"absolute"} top={0} left={"-10000px"} visibility={"hidden"}>
            <Box overflow={"clip"} pos={"relative"} as={"div"} hidden={true} ref={ref} w={"806px"} backgroundColor={"white"}
                  borderWidth={"1px"} >
                <WaterMark/>
                {
                    props.children
                }
            </Box>
        </Box>
    )
})

export default TemplateWrapper