import { IQuotes } from 'Types/Interface/IQuotes';
import { apiRoutes } from './apiRoutes';
import { reqClient } from './reqClient';
import { IReceipt } from 'Types/Interface/IReceipt';
import { IImageUploadResponse } from 'Types/Interface/IRequestBody';

export const quotes = async (
	quotesRequestBody: IQuotes
): Promise<number> => {

	try {
		const response = await reqClient.post(apiRoutes.quotes, quotesRequestBody);

		return response.data.id;
		
	} catch (error) {
		console.error('error', error);
		throw new Error();
	}
	
};

export const receipt = async (
	receiptRequestBody: IReceipt
): Promise<number> => {

	try {
		const response = await reqClient.post(apiRoutes.receipt, receiptRequestBody);

		return response.data.id;
		
	} catch (error) {
		console.error('error', error);
		throw new Error();
	}
	
};

export const LogoUpload = async (formData: FormData): Promise<IImageUploadResponse>  => {
	try {
	  const response =  await reqClient.post(apiRoutes.logoUpload, formData, {
		headers: {
		  'Content-Type': 'multipart/form-data',
		},
	  });
	  return response.data;
	} catch (error) {
	  throw new Error('Error uploading image');
	}
};