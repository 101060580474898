import {Box, Checkbox, Flex, FormControl, FormLabel, Input, Select, Text} from "@chakra-ui/react";


const PeriodicPayStubSection = () => {
  return (
      <Box p={"32px"} borderRadius={"8px"} borderWidth={"1px"} borderColor={"#E2E4E5"}>
        <PeriodicPayStubHeader/>
        <Flex gap={"48px"} my={"32px"}>
          <PayDateStartInput/>
          <PayDateEndInput/>
        </Flex>
        <Flex gap={"48px"}>
          <PayDateInput/>
          <TotalHoursSelector/>
        </Flex>
      </Box>
  )
}

const PeriodicPayStubHeader = () => {
  return (
      <Flex justifyContent={"space-between"}>
        <Text fontSize={"24px"} fontWeight={"semibold"} color={"#208c55"}>
          PayStub 1
        </Text>
        <Checkbox>
          Add Deposit slip
        </Checkbox>
      </Flex>
  )
}

const PayDateStartInput = () => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date Start
        </FormLabel>
        <Input type={"date"}/>
      </FormControl>
  )
}

const PayDateEndInput = () => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date End
        </FormLabel>
        <Input type={"date"}/>
      </FormControl>
  )
}

const PayDateInput = () => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date
        </FormLabel>
        <Input type={"date"}/>
      </FormControl>
  )
}

const TotalHoursSelector = () => {
  return (
      <FormControl>
        <FormLabel>
          Total Hours
        </FormLabel>
        <Select variant={"flushed"}>
          <option value={40}>40</option>
          <option value={39}>39</option>
        </Select>
      </FormControl>
  )
}

export default PeriodicPayStubSection