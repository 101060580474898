import {Box, Text, Flex, Image} from "@chakra-ui/react"
import interfaceImage from "../../../Assets/Images/Interface.png"
import storage from "../../../Assets/Images/Storage.png"
import analytics from "../../../Assets/Images/Analytics.png"
import {theme} from "../../../Theme/Theme";

type FeatureProps = {
    image: string
    title: string
    body: string
}

const features: FeatureProps[] = [
    {
        image: interfaceImage,
        title: "Friendly Interface",
        body: "You can understand workhub on one glance"
    },
    {
        image: storage,
        title: "Storage",
        body: "Your document is saved with us, and can later be re-generated when lost"
    },
    {
        image: analytics,
        title: "Calculation analytics",
        body: "Get accurate calculations to match the numbers inputed"
    }
]

const FeaturesSection = () => {
  return (
      <Box py={"94px"} px={"166px"} background={theme.primaryFixed}>
          <Flex gap={"33px"} justifyContent={"space-between"} py={"74px"} w={"full"}>
              {
                  features.map((feature) => <Feature key={feature.title} image={feature.image} title={feature.title} body={feature.body}/>)
              }
          </Flex>

      </Box>
  )
}

const Feature = ({image, title, body}: FeatureProps) => {
    return (
        <Box w={364}>
            <Box maxW={364} maxH={430}  mb={"30px"}>
                <Image src={image}/>
            </Box>
            <Box w={340}>
                <Text fontSize={"30px"} color={theme.onSurface} mb={"20px"}>
                    {title}
                </Text>
                <Text color={theme.secondaryFixed} fontSize={"18px"}>
                    {body}
                </Text>
            </Box>
        </Box>
    )
}

export default FeaturesSection