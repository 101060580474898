import { Box, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import BusinessFormsHeading from "../../../Components/Forms/BusinessFormsHeading";
import {
    selectW2TaxFormCount, selectW2TaxState,
    selectW2TaxYear, setFormCount, setW2State, setW2year,
} from "../../../../Store/Slices/w2Slice";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import { useAppSelector } from "../../../../Hooks/useAppSelector";
import { PatternFormat } from "react-number-format";
import DropSelect from "Pages/Components/Inputs/DropSelect";
import StateDropSelect from "../../../Components/StateDropSelect";
import TextInput from "../../../Components/Inputs/TextInput";
import useFormCounter from "../../../../Hooks/useFormCounter";
import React from "react"

const W2BasicInfo = () => {
    const year = useAppSelector(selectW2TaxYear)
    const state = useAppSelector(selectW2TaxState)
    const formCount = useAppSelector(selectW2TaxFormCount)
    const dispatch = useAppDispatch()
    const formCounts = useFormCounter()

    return (
        <Box mb={"32px"}>
            <BusinessFormsHeading title={"Basic Info"} steps={3} currentStep={1} />
            <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                <FormControl isRequired mt={"20px"} maxWidth={"150px"}>
                    <FormLabel>
                        Tax year
                    </FormLabel>
                    <PatternFormat
                        focusBorderColor="primary"
                        customInput={Input}
                        flexBasis={"27%"}
                        value={year}
                        name={'year'}
                        format={"####"}
                        onChange={(event) => dispatch(setW2year(event.target.value))}
                        type={"text"}
                        placeholder={"YYYY"} />
                </FormControl>
                <StateDropSelect
                    value={state}
                    flexBasis={"40%"}
                    notFormik={true}
                    onChange={(event) => dispatch(setW2State(event.target.value))}
                    label={"State for tax calculations"}
                    name={'state'} />
                <DropSelect
                    isRequired
                    flexBasis={"40%"}
                    value={formCount}
                    onChange={(event) => dispatch(setFormCount(parseInt(event.target.value)))}
                    label={"How many forms do you need?"} name={'formCount'}>
                    {
                        formCounts.slice(0, 3).map((count) => <option key={count}
                            value={count + 1}>{count + 1}</option>)
                    }
                </DropSelect>
            </Flex>
            <Flex gap={"24px"} w={"70%"}>


            </Flex>
        </Box>
    )
}

export default W2BasicInfo