import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './receipt';
import { apiRoutes } from 'Api/apiRoutes';
import { IDocumentPrice, IPrices } from 'Types/Interface/IPricing';

export const priceApi = createApi({
    reducerPath: 'priceApi',
    baseQuery,
    endpoints: (builder) => ({
        getPriceLists: builder.query<{ prices: IPrices[] }, void>({
            query: () => apiRoutes.prices,
            // transformResponse: (response: { prices: IPrices[] }) => response.prices,
        }),
        getPriceByDocumentType: builder.query<IDocumentPrice, number>({
            query: (documentType) => `/GetPriceByDocumentType?documentType=${documentType}`
        })
    })
})

export const {useGetPriceListsQuery, useGetPriceByDocumentTypeQuery} = priceApi