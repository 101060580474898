import {Box, Flex, Text} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import {AppRoutes} from "../Routes/AppRoutes";

const AppFooter = () => {
  return (
      <Flex direction={{base: "column", lg: "row"}} gap={"16px"} pt={"70px"} px={'55px'} pb={"100px"} borderTopWidth={'0.5px'} borderTopColor={"#1fbc6c80"}>
          <Box flexBasis={{base: "25%"}} display={{base: "none", lg:"block"}} px={"16px"}>
              <Text fontSize={"24px"} fontWeight={"semibold"} mb={"16px"}>About us</Text>
              <Text>
                  PHCWorkHub provides customizable tax, business, legal, and personal forms, simplifying document generation for individuals, small businesses, and entrepreneurs with an easy-to-use, comprehensive online platform.
              </Text>
          </Box>
          <Box flexBasis={{base: "25%"}} fontSize={"24px"}>
              <NavLink to={AppRoutes.home}><Text fontSize={"24px"} fontWeight={"semibold"} mb={"16px"}>Company</Text></NavLink>
              <NavLink to={AppRoutes.home}><Text>About us</Text></NavLink>
              <NavLink to={AppRoutes.pricing}><Text>Pricing</Text></NavLink>
              <NavLink to={AppRoutes.home}><Text>FAQs</Text></NavLink>
              <NavLink to={AppRoutes.home}><Text>How it works</Text></NavLink>
              <NavLink to={AppRoutes.home}><Text>Customer Stories</Text></NavLink>
          </Box>
          <Box flexBasis={{base: "25%"}} fontSize={"24px"}>
              <Text fontSize={"24px"} fontWeight={"semibold"} mb={"16px"}>Explore</Text>
              <NavLink to={AppRoutes.payStub}><Text>Paystub</Text></NavLink>
              <NavLink to={AppRoutes.invoice}><Text>Invoice</Text></NavLink>
              <NavLink to={AppRoutes.quotes}><Text>Quotes</Text></NavLink>
              <NavLink to={AppRoutes.w2}><Text>W2</Text></NavLink>
              <NavLink to={AppRoutes.f1099}><Text>1099</Text></NavLink>
              <NavLink to={AppRoutes.receipt}><Text>Receipt</Text></NavLink>
          </Box>
          <Box flexBasis={{base: "25%"}}>
              <Text fontSize={"24px"} fontWeight={"semibold"} mb={"16px"}>Contact us</Text>
              <Flex justifyContent={"space-between"} mb={"16px"} w={{base: "315px", lg: "full"}}>
                  {/*    Social media links*/}
                  <Whatsapp/>
                  <Phone/>
                  <Gmail/>
                  <LinkedIn/>
                  <Instagram/>
              </Flex>
              <Text>support@phcworkhub.com</Text>
              <Text w={"315px"}>3255 Lawrenceville Suwanee Rd Site P PMB 630 Suwanee, GA 30024</Text>
          </Box>
      </Flex>
  )
}

const Instagram = ()=> {
    return (
        <svg width="24" height="24" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3107_1597)">
                <path
                    d="M24.3317 0.555664H10.6342C5.06741 0.555664 0.538513 5.08456 0.538513 10.6513V24.3489C0.538513 29.9157 5.06741 34.4446 10.6342 34.4446H24.3317C29.8985 34.4446 34.4274 29.9157 34.4274 24.3489V10.6513C34.4273 5.08456 29.8984 0.555664 24.3317 0.555664ZM31.0182 24.3489C31.0182 28.0417 28.0245 31.0353 24.3317 31.0353H10.6342C6.9414 31.0353 3.94773 28.0417 3.94773 24.3489V10.6513C3.94773 6.95849 6.9414 3.96488 10.6342 3.96488H24.3317C28.0245 3.96488 31.0182 6.95849 31.0182 10.6513V24.3489Z"
                    fill="url(#paint0_linear_3107_1597)"/>
                <path
                    d="M17.4829 8.73535C12.6499 8.73535 8.71802 12.6673 8.71802 17.5002C8.71802 22.3331 12.6499 26.265 17.4829 26.265C22.3158 26.265 26.2478 22.3331 26.2478 17.5002C26.2478 12.6672 22.3158 8.73535 17.4829 8.73535ZM17.4829 22.8559C14.525 22.8559 12.1272 20.4581 12.1272 17.5002C12.1272 14.5424 14.5251 12.1446 17.4829 12.1446C20.4408 12.1446 22.8385 14.5424 22.8385 17.5002C22.8385 20.458 20.4407 22.8559 17.4829 22.8559Z"
                    fill="url(#paint1_linear_3107_1597)"/>
                <path
                    d="M26.2642 10.9012C27.4241 10.9012 28.3644 9.96086 28.3644 8.80093C28.3644 7.641 27.4241 6.70068 26.2642 6.70068C25.1043 6.70068 24.1639 7.641 24.1639 8.80093C24.1639 9.96086 25.1043 10.9012 26.2642 10.9012Z"
                    fill="url(#paint2_linear_3107_1597)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_3107_1597" x1="17.483" y1="34.3459" x2="17.483"
                                y2="0.818885" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E09B3D"/>
                    <stop offset="0.3" stopColor="#C74C4D"/>
                    <stop offset="0.6" stopColor="#C21975"/>
                    <stop offset="1" stopColor="#7024C4"/>
                </linearGradient>
                <linearGradient id="paint1_linear_3107_1597" x1="17.4829" y1="34.346" x2="17.4829"
                                y2="0.819003" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E09B3D"/>
                    <stop offset="0.3" stopColor="#C74C4D"/>
                    <stop offset="0.6" stopColor="#C21975"/>
                    <stop offset="1" stopColor="#7024C4"/>
                </linearGradient>
                <linearGradient id="paint2_linear_3107_1597" x1="26.2642" y1="34.3458" x2="26.2642"
                                y2="0.818773" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E09B3D"/>
                    <stop offset="0.3" stopColor="#C74C4D"/>
                    <stop offset="0.6" stopColor="#C21975"/>
                    <stop offset="1" stopColor="#7024C4"/>
                </linearGradient>
                <clipPath id="clip0_3107_1597">
                    <rect width="33.8889" height="33.8889" fill="white"
                          transform="translate(0.538696 0.555664)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const LinkedIn = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3107_1594)">
                <path
                    d="M27.5831 0.556641H3.03716C1.86513 0.556641 0.91095 1.48652 0.91095 2.63154V27.2819C0.91095 28.4296 1.86513 29.3613 3.03716 29.3613H27.5849C28.7596 29.3613 29.7165 28.4296 29.7165 27.2819V2.63154C29.7165 1.48652 28.7596 0.556641 27.5831 0.556641Z"
                    fill="#0177B5"/>
                <path
                    d="M5.18241 11.355H9.46274V25.1006H5.18241V11.355ZM7.31943 4.51367C8.68499 4.51367 9.7949 5.62359 9.7949 6.98915C9.7949 8.35471 8.68499 9.46733 7.32033 9.46733C6.66343 9.4659 6.03383 9.20438 5.56925 8.73997C5.10466 8.27555 4.84291 7.64605 4.84125 6.98915C4.84125 6.66384 4.90537 6.34172 5.02994 6.0412C5.15451 5.74069 5.3371 5.46767 5.56725 5.23777C5.79741 5.00786 6.07062 4.82558 6.37127 4.70133C6.67192 4.57709 6.99411 4.51332 7.31943 4.51367ZM12.1354 11.355H16.2329V13.2337H16.2897C16.8604 12.1534 18.2538 11.0138 20.3404 11.0138C24.6667 11.0138 25.4651 13.8611 25.4651 17.5617V25.1015H21.1956V18.4151C21.1956 16.8217 21.1668 14.7694 18.9758 14.7694C16.7523 14.7694 16.4103 16.5067 16.4103 18.298V25.0979H12.1417V11.3523L12.1354 11.355Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3107_1594">
                    <rect width="28.8056" height="28.8056" fill="white"
                          transform="translate(0.91095 0.555664)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const Gmail = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3107_1588)">
                <path
                    d="M2.50992 30.212H7.90108V17.1182L0.199829 11.3423V27.9012C0.199736 28.2047 0.259444 28.5053 0.375539 28.7857C0.491635 29.0661 0.661844 29.3208 0.87644 29.5354C1.09104 29.75 1.34581 29.9202 1.62621 30.0363C1.90661 30.1524 2.20644 30.2121 2.50992 30.212Z"
                    fill="#4285F4"/>
                <path
                    d="M26.3856 30.212H31.7768C32.0803 30.2122 32.3809 30.1526 32.6614 30.0365C32.9419 29.9205 33.1968 29.7503 33.4114 29.5357C33.6261 29.3211 33.7964 29.0663 33.9125 28.7858C34.0286 28.5054 34.0884 28.2048 34.0883 27.9012V11.3423L26.3856 17.1182"
                    fill="#34A853"/>
                <path
                    d="M26.3856 7.10603V17.1181L34.0876 11.3421V8.26107C34.0876 5.40382 30.8258 3.77433 28.5425 5.48854"
                    fill="#FBBC04"/>
                <path d="M7.90161 17.1189V7.10547L17.1448 14.0379L26.3866 7.10617V17.1182L17.1448 24.0499"
                      fill="#EA4335"/>
                <path
                    d="M0.199192 8.26107V11.3421L7.90044 17.1181V7.10603L5.74426 5.48854C3.45676 3.77433 0.198486 5.40382 0.198486 8.26107"
                    fill="#C5221F"/>
            </g>
            <defs>
                <clipPath id="clip0_3107_1588">
                    <rect width="33.8889" height="33.8889" fill="white"
                          transform="translate(0.200012 0.555664)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const Phone = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.337 25.4028C30.337 25.8908 30.2285 26.3924 29.9981 26.8804C29.7677 27.3684 29.4694 27.8293 29.0763 28.263C28.4121 28.995 27.6801 29.5237 26.8532 29.8626C26.0399 30.2015 25.1588 30.3777 24.2099 30.3777C22.8272 30.3777 21.3497 30.0524 19.7908 29.3882C18.2319 28.7239 16.673 27.8293 15.1277 26.7042C13.5688 25.5655 12.0912 24.3048 10.6814 22.9086C9.28521 21.4988 8.02454 20.0213 6.89943 18.4759C5.78787 16.9306 4.89321 15.3853 4.24254 13.8535C3.59187 12.3082 3.26654 10.8306 3.26654 9.42082C3.26654 8.49905 3.42921 7.61794 3.75454 6.8046C4.07987 5.97771 4.59498 5.2186 5.31343 4.54082C6.18098 3.68682 7.12987 3.2666 8.13299 3.2666C8.51254 3.2666 8.8921 3.34793 9.23099 3.5106C9.58343 3.67327 9.89521 3.91727 10.1392 4.26971L13.2841 8.70238C13.5281 9.04127 13.7043 9.35305 13.8263 9.65127C13.9483 9.93593 14.0161 10.2206 14.0161 10.4782C14.0161 10.8035 13.9212 11.1288 13.7314 11.4406C13.5552 11.7524 13.2977 12.0777 12.9723 12.403L11.9421 13.4739C11.793 13.623 11.7252 13.7993 11.7252 14.0162C11.7252 14.1246 11.7388 14.2195 11.7659 14.3279C11.8065 14.4364 11.8472 14.5177 11.8743 14.599C12.1183 15.0464 12.5385 15.6293 13.135 16.3342C13.745 17.039 14.3957 17.7575 15.1005 18.4759C15.8325 19.1944 16.5374 19.8586 17.2559 20.4686C17.9608 21.065 18.5437 21.4717 19.0045 21.7157C19.0723 21.7428 19.1537 21.7835 19.2485 21.8242C19.357 21.8648 19.4654 21.8784 19.5874 21.8784C19.8179 21.8784 19.9941 21.797 20.1432 21.6479L21.1734 20.6313C21.5123 20.2924 21.8377 20.0348 22.1494 19.8722C22.4612 19.6824 22.773 19.5875 23.1119 19.5875C23.3694 19.5875 23.6405 19.6417 23.9388 19.7637C24.237 19.8857 24.5488 20.0619 24.8877 20.2924L29.3745 23.4779C29.727 23.7219 29.971 24.0066 30.1201 24.3455C30.2557 24.6844 30.337 25.0233 30.337 25.4028Z"
                stroke="#292D32" strokeWidth="2.03333" strokeMiterlimit="10"/>
        </svg>
    )
}

const Whatsapp = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.69727 17.3476C0.69624 20.3072 1.473 23.1964 2.94947 25.7429L0.555542 34.4446L9.50052 32.1096C11.9651 33.4475 14.74 34.1528 17.5637 34.1538H17.5709C26.8702 34.1538 34.4405 26.6197 34.4444 17.3611C34.446 12.8738 32.6922 8.65448 29.5068 5.48034C26.3208 2.30655 22.0846 0.557543 17.5709 0.555664C8.26992 0.555664 0.701045 8.08856 0.69727 17.3476ZM6.02407 25.3044L5.69017 24.7766C4.2861 22.5541 3.54504 19.9858 3.54606 17.3486C3.54915 9.65291 9.84025 3.39192 17.5763 3.39192C21.3224 3.39346 24.8433 4.84729 27.4914 7.48523C30.1394 10.1233 31.5965 13.6302 31.5955 17.36C31.592 25.0559 25.3008 31.3177 17.5709 31.3177H17.5654C15.0485 31.3164 12.5801 30.6435 10.4272 29.3719L9.91489 29.0694L4.60679 30.4549L6.02407 25.3044Z"
              fill="url(#paint0_linear_3107_1577)"/>
          <path
              d="M1.27789 17.353C1.27703 20.2098 2.02685 22.9992 3.45253 25.4578L1.14148 33.8583L9.77675 31.6042C12.1558 32.895 14.8345 33.5764 17.5606 33.5771H17.5678C26.5451 33.5771 33.8528 26.3046 33.8566 17.3658C33.8583 13.0342 32.1654 8.96125 29.0898 5.89695C26.0147 2.83282 21.9248 1.14446 17.5671 1.14258C8.58888 1.14258 1.28166 8.41463 1.27789 17.353Z"
              fill="url(#paint1_linear_3107_1577)"/>
          <path
              d="M13.3536 10.3273C13.0377 9.62835 12.7054 9.61434 12.4049 9.60204C12.1591 9.59162 11.8778 9.59229 11.5969 9.59229C11.3157 9.59229 10.859 9.69753 10.4727 10.1174C10.0862 10.5374 8.99695 11.5528 8.99695 13.6178C8.99695 15.683 10.5079 17.6785 10.7184 17.9588C10.9293 18.2386 13.6352 22.6119 17.9205 24.2943C21.482 25.6924 22.2068 25.4143 22.9798 25.3443C23.7528 25.2744 25.4743 24.3293 25.8255 23.3491C26.1769 22.3691 26.1769 21.5291 26.0715 21.3536C25.9662 21.1787 25.685 21.0737 25.2634 20.8639C24.8416 20.654 22.7689 19.6384 22.3825 19.4984C21.9959 19.3585 21.7149 19.2886 21.4336 19.7088C21.1526 20.1285 20.3451 21.0737 20.0991 21.3536C19.8532 21.6341 19.6071 21.6691 19.1856 21.4592C18.7638 21.2486 17.4059 20.8058 15.7951 19.3761C14.5417 18.2635 13.6956 16.8896 13.4495 16.4694C13.2037 16.0497 13.4233 15.8224 13.6347 15.6131C13.8241 15.425 14.0564 15.123 14.2673 14.8781C14.4777 14.633 14.5478 14.458 14.6884 14.1781C14.8291 13.8979 14.7587 13.6528 14.6534 13.4429C14.5478 13.2329 13.7285 11.1573 13.3536 10.3273Z"
              fill="white"/>
          <defs>
              <linearGradient id="paint0_linear_3107_1577" x1="1695" y1="3389.45" x2="1695" y2="0.55122"
                              gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F9F9F9"/>
                  <stop offset="1" stopColor="white"/>
              </linearGradient>
              <linearGradient id="paint1_linear_3107_1577" x1="1636.9" y1="3272.72" x2="1636.9" y2="1.14414"
                              gradientUnits="userSpaceOnUse">
                  <stop stopColor="#1FAF38"/>
                  <stop offset="1" stopColor="#60D669"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default AppFooter