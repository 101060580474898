import React from "react";
import {Route, Routes} from "react-router-dom";
import {AppRoutes} from "./AppRoutes";
import Home from "../Pages/HomePageV2/Home";
import {Pricing} from "../Pages/Pricing";
import {LoginPage} from "Pages/LoginPage/LoginPage";
import {SignUpPage} from "Pages/SignUpPage/SignUpPage";
import Quotes from "Pages/QuotesV2/Quotes";
import Receipt from "Pages/ReceiptV2/Receipt";
import {Checkout} from "Pages/Checkout/Checkout";
import {Return} from "Pages/Checkout/Return";
import PayStub from "../Pages/PayStubPage/PayStub";
import W2 from "../Pages/W2/W2";
import F1099 from "../Pages/1099Misc/1099";
import F1099Nec from "../Pages/1099Nec";

export const MainRoute = () => {
    return (
        <Routes>
            <Route path={AppRoutes.home} element={<Home/>}/>
            <Route path={AppRoutes.pricing} element={<Pricing/>}/>
            <Route path={AppRoutes.login} element={<LoginPage/>}/>
            <Route path={AppRoutes.signUp} element={<SignUpPage/>}/>
            <Route path={AppRoutes.quotes} element={<Quotes/>}/>
            <Route path={AppRoutes.receipt} element={<Receipt/>}/>
            <Route path={AppRoutes.checkout} element={<Checkout/>}/>
            <Route path={AppRoutes.return} element={<Return/>}/>
            <Route path={AppRoutes.payStub} element={<PayStub/>}/>
            <Route path={AppRoutes.w2} element={<W2/>}/>
            <Route path={AppRoutes.f1099Misc} element={<F1099/>}/>
            <Route path={AppRoutes.f1099Nec} element={<F1099Nec/>}/>
        </Routes>
    );
};
