import {Box, Flex, Text, FormControl, FormLabel, Input, Select} from "@chakra-ui/react";

const CompanyInfoForm = () => {
    return (
        <Box mb={"49px"}>
            <CompanyInfoHeading/>
            <Flex gap={"24px"} mb={"30px"}>
                <CompanyNameInput/>
                <CompanyAddressInput/>
            </Flex>
            <Flex gap={"24px"} align={"end"}>
                <CompanyCityInput/>
                <CompanyStateSelector/>
                <CompanyZipCodeInput/>
            </Flex>
        </Box>
    )
}

const CompanyInfoHeading = () => {
    return (
        <Box mb={"30px"}  borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"} >
                Company's Information
            </Text>
        </Box>
    )
}

const CompanyCityInput = () => {
    return (
        <FormControl>
            <FormLabel>
                City
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const CompanyAddressInput = () => {
    return (
        <FormControl>
            <FormLabel>
                Company Address
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const CompanyNameInput = () => {
    return (
        <FormControl flexBasis={"50%"}>
            <FormLabel>
                Company Name
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

const CompanyStateSelector = () => {
    return (
        <Select variant={"filled"} placeholder={"State"} flexBasis={"25%"}>
            <option value={"Ohio"}>Ohio</option>
            <option value={"new-york"}>New york</option>
        </Select>
    )
}

const CompanyZipCodeInput = () => {
    return (
        <FormControl flexBasis={"25%"}>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input type={"text"}/>
        </FormControl>
    )
}

export default CompanyInfoForm