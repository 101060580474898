import {TextareaProps} from "@chakra-ui/react";
import {FormikInputProps} from "./types";
import { useField } from "formik";
import NoteInput from "../Inputs/NoteInput";

const FormikTextArea = ({...props} : FormikInputProps & TextareaProps) => {
    const [field, meta] = useField(props.name);
   return (
           <div>
               <NoteInput focusBorderColor='primary' {...field}  {...props}/>
              {meta.touched &&
                meta.error && <div className="error">{meta.error}</div>}
           </div>
   )
}


export default FormikTextArea