import {Box} from "@chakra-ui/react";
import FormHeading from "../../Components/FormHeading";
import EmployeeInfoForm from "./Forms/EmployeeInfoForm";
import PayStubInfoForm from "./Forms/PayStubInfoForm";
import CompanyInfoForm from "./Forms/CompanyInfoForm";
import PeriodicPayStubSection from "./PeriodicPayStubSection";

const PayStubForms = () => {
    return (
        <Box flexBasis={"50%"}>
            <FormHeading title={"Create Paystub"}
                         description={"Fill in your information below."}/>
            <EmployeeInfoForm/>
            <PayStubInfoForm/>
            <CompanyInfoForm/>
            <PeriodicPayStubSection/>
        </Box>
    )
}

export default PayStubForms