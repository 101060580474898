import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type TemplateState = {
    image: string | null
    preview: boolean
}

const initialState: TemplateState = {
    image: "",
    preview: false,
}

// general form template slice for saving image of preview
const templateSlice = createSlice({
    name: "template",
    initialState,
    reducers: {
        setTemplateImagePreview: (state, action: PayloadAction<string>)=>{
            state.image = action.payload
        },
        togglePreview: (state)=>{
            state.preview = !state.preview
        }
    }

})

export const {setTemplateImagePreview} = templateSlice.actions
export default templateSlice.reducer
